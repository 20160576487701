import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import "./index.less"
import App from "./App"

import "./assets/css/bootstrap.min.css"
import "./assets/css/custom-animated.css"
import "./assets/css/default.css"
import "./assets/css/font-awesome.min.css"
import "./assets/css/magnific-popup.css"
import "./assets/css/main.css"
import "./assets/css/style.css"


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
