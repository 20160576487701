import React from "react"
import heroThumbOne from "../../assets/images/hero-thumb-1.png"
import heroThumbTwo from "../../assets/images/hero-thumb-2.png"
import shapeTwo from "../../assets/images/shape/shape-2.png"
import shapeThree from "../../assets/images/shape/shape-3.png"
import shapeFour from "../../assets/images/shape/shape-4.png"
import homeScreenshot from "../../assets/images/screenshots/home-ss.png"
import _get from "lodash/get"
import { isWechatBrowser } from "../../utils/helper"
import { Modal } from "antd"
import { isAndroid } from "react-device-detect"

function HeroHomeOne({ downloadLinks }) {
  const download = (platform: string) => {
    if (isWechatBrowser()) {
      Modal.info({
        maskClosable: true,
        icon: null,
        okButtonProps: { style: { display: "none" } },
        content: (
          <div>
            <div
              style={{
                fontSize: "1.4em",
                textAlign: "center",
              }}
            >
              点击右上角在浏览器打开
            </div>

            <div
              style={{
                textAlign: "center",
                marginTop: 24,
              }}
            >
              <img
                src="https://file.ilux.ai/6SpHm8UR58tv7kfXYdUVBxHicDRNNeVB/app-icon-216.png"
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 18,
                  marginBottom: 6,
                  boxShadow: "0 4px 6px rgba(0,0,0,0.14)",
                }}
              />
              <div>较真bReal</div>
            </div>
          </div>
        ),
      })
    } else {
      let downloadUrl = _get(downloadLinks, platform)
      window.open(downloadUrl)
    }
  }

  return (
    <>
      <section className="appie-hero-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-content">
                <span>较真bReal</span>
                <h1 className="appie-title">
                  <div>专业正品二手奢侈品</div>
                  <div>鉴定·回收·交易</div>
                </h1>
                <p>Friends don't let friends wear fake.</p>
                <ul>
                  <li>
                    <a onClick={() => download("ios")}>
                      <i className="fab fa-apple" />
                      iOS下载
                    </a>
                  </li>
                  <li>
                    <a className="item-2" onClick={() => download("android")}>
                      <i className="fab fa-google-play" />
                      安卓下载
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-hero-thumb">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={homeScreenshot} alt="" width={310} />
                </div>
                {/* <div
                  className="thumb-2 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <img src={heroThumbTwo} alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="hero-shape-1">
          <img src={shapeTwo} alt="" />
        </div>
        <div className="hero-shape-2">
          <img src={shapeThree} alt="" />
        </div>
        <div className="hero-shape-3">
          <img src={shapeFour} alt="" />
        </div>
      </section>
    </>
  )
}

export default HeroHomeOne
