import React, { useState } from "react"
import { Link } from "react-router-dom"
import logo from "../../assets/images/logo.svg"
import jh from "../../assets/images/jh.jpeg"
import { Modal } from "antd"
import _get from "lodash/get"
import { isWechatBrowser } from "../../utils/helper"

function FooterHomeOne({
  className,
  downloadLinks,
}: {
  className?: string
  downloadLinks?: object
}) {
  const showWeixinCsQr = () => {
    Modal.info({
      icon: null,
      title: "微信客服",
      content: (
        <img src="https://file.ilux.ai/wLAkxkcR2h8kVYYHMyy5p8D6CD3HYapS/dagu-qr.jpeg" />
      ),
      okButtonProps: { style: { display: "none" } },
      maskClosable: true,
    })
  }

  const showWeixinMpQr = () => {
    Modal.info({
      icon: null,
      title: "微信客服",
      content: <img src="https://file.ilux.ai/3a4d136a074fd0ab9b3d.jpg" />,
      okButtonProps: { style: { display: "none" } },
      maskClosable: true,
    })
  }

  return (
    <>
      <section className={`appie-footer-area ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="较真bReal" height={40} />
                  </a>
                </div>
                <p>较真App: 专业奢侈品鉴定·回收·交易平台</p>
                <div className="social mt-30">
                  <ul>
                    <li>
                      <a onClick={showWeixinCsQr}>
                        <i className="fab fa-weixin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">关于</h4>
                <ul>
                  <li>
                    <Link to="/about-us">关于我们</Link>
                  </li>
                  <li>
                    <a onClick={showWeixinMpQr}>微信公众号</a>
                  </li>
                  {/* <li>
                    <a href="#">常见问题</a>
                  </li> */}
                  <li>
                    <Link to="/app_privacy_policy">隐私条款</Link>
                  </li>
                  <li>
                    <Link to="/app_tos">用户注册协议</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">联系我们</h4>
                <ul>
                  <li>
                    <a href="mailto:support@breal.co">
                      <i className="fa fa-envelope" /> support@breal.co
                    </a>
                  </li>
                  <li>
                    <a onClick={showWeixinCsQr}>
                      <i className="fab fa-weixin" /> jiaozhen101
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-download" /> APP下载
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                <div className="apps-download-btn">
                  <ul>
                    <li>
                      <a
                        href={_get(downloadLinks, "ios", "#")}
                        onClick={
                          !isWechatBrowser()
                            ? null
                            : () => {
                                if (isWechatBrowser()) {
                                  Modal.info({
                                    maskClosable: true,
                                    icon: null,
                                    okButtonProps: {
                                      style: { display: "none" },
                                    },
                                    content: (
                                      <div>
                                        <div
                                          style={{
                                            fontSize: "1.4em",
                                            textAlign: "center",
                                          }}
                                        >
                                          点击右上角在浏览器打开
                                        </div>

                                        <div
                                          style={{
                                            textAlign: "center",
                                            marginTop: 24,
                                          }}
                                        >
                                          <img
                                            src="https://file.ilux.ai/6SpHm8UR58tv7kfXYdUVBxHicDRNNeVB/app-icon-216.png"
                                            style={{
                                              width: 80,
                                              height: 80,
                                              borderRadius: 18,
                                              marginBottom: 6,
                                              boxShadow:
                                                "0 4px 6px rgba(0,0,0,0.14)",
                                            }}
                                          />
                                          <div>较真bReal</div>
                                        </div>
                                      </div>
                                    ),
                                  })
                                }
                              }
                        }
                      >
                        <i className="fab fa-apple" /> iOS下载
                      </a>
                    </li>
                    <li>
                      <a
                        className="item-2"
                        href={_get(downloadLinks, "android", "#")}
                      >
                        <i className="fab fa-google-play" />
                        Android下载
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="copyright-text">
                  <p>© 2017 - 2021 iLux® 广州爱丽社科技有限公司</p>
                  <p>
                    <a style={{ color: "#333333" }} href="https://breal.co">
                      较真 bReal
                    </a>{" "}
                    <img src={jh} />
                    <a href="http://beian.miit.gov.cn/">粤ICP备17136441号</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FooterHomeOne
