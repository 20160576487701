import Certificate from "./component/Certificate/Certificate"
import React, { useEffect, useState } from "react"
import "./AuthCert.less"
import API from "../../../../api/api"
import { Col, message, Row } from "antd"
import RulePhotoGrid from "./component/RulePhotoGrid/RulePhotoGrid"
import { useParams } from "react-router"
import SectionTitle from "./component/SectionTitle/SectionTitle"

interface AuthCertProps {}

const AuthCert = () => {
  let params = useParams()
  let id = params.id

  const [certData, setCertData] = useState()

  useEffect(() => {
    console.log("shit")
    getCertData()
  }, [id])

  const getCertData = () => {
    API.Authenticate.getCertificate({ id: parseInt(id) })
      .then((data) => {
        console.log(`data`, data)
        setCertData(data)
      })
      .catch((err) => {
        message.error({ content: "出错了" + err })
      })
  }

  return (
    <Certificate certData={certData} />
  )
}

export default AuthCert
