import React from "react"
import { Link } from "react-router-dom"

function Navigation() {
  return (
    <>
      <ul>
        <li>
          <a href="/">
            首页 <i className="fal" />
          </a>
        </li>
        <li>
          <Link to="/service">服务</Link>
        </li>
        {/* <li>
          <a href="#">
            Pages <i className="fal fa-angle-down" />
          </a>
          <ul className="sub-menu">
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <Link to="/error">Error</Link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            News <i className="fal fa-angle-down" />
          </a>
          <ul className="sub-menu">
            <li>
              <Link to="/news">News Page</Link>
            </li>
            <li>
              <Link to="/news/single-news">Single News</Link>
            </li>
          </ul>
        </li> */}
        <li>
          <Link to="/contact">联系我们</Link>
        </li>
      </ul>
    </>
  )
}

export default Navigation
