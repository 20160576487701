import moment from "moment"
import React, { useState } from "react"
import { StringMappingType } from "typescript"
import "./Certificate.less"
import { CertData } from "../../../interface"

interface CertificateProps {
  certData: CertData
}

const Certificate = ({ certData }: CertificateProps) => {
  if (!certData) {
    return <div className="cert-container">loading....</div>
  }

  const logoUrl = "https://oss.breal.co/certificate/cert-logo%403x.png"
  const certBadgeUrl = "https://oss.breal.co/certificate/cert-badge%403x.png"
  const bgUrl = "https://oss.breal.co/certificate/cert-bg%403x.png"
  let certId = "AUN-" + certData.id
  let coverUrl = certData.cover
  let brandName = certData.auth_brand.name
  let brandCnName = certData.auth_brand.cn_name
  let authTypeName = certData.auth_type.cn_name
  let authDate = moment(certData.appraisal.date * 1000).format(
    "YYYY-MM-DD HH:MM"
  )
  let nDataPoints = certData.rule_photos.length

  return (
    <div className="cert-container">
      <div className="cert-wrapper">
        <div className="bg-image"></div>
        <div className="header">
          <img src={logoUrl} className="logo" />
        </div>
        <div className="body">
          <div className="cert-title">
            <div>
              <h1>鉴定证书</h1>
            </div>
            <div>
              <h2>CERTIFICATE</h2>
            </div>
            <div>
              <h3>OF AUTHENTICATION</h3>
            </div>
          </div>

          <div className="cover-image-wrapper">
            <div
              className="cover-image"
              style={{ backgroundImage: `url(${coverUrl})` }}
            />
            <img src={certBadgeUrl} className="cert-badge" />
          </div>

          {/* text */}
          <div className="info-text-wrapper">
            <div className="info-text">
              品牌: {brandName}{" "}
              {brandCnName != brandName ? `(${brandCnName})` : ""}
            </div>
            <div className="info-text">类目名称: {authTypeName}</div>
            <div className="info-text">
              鉴定依据: 行业通用标准。通过{nDataPoints}
              个由委托人提供的图片鉴定点对比品牌正品工艺。
            </div>
            <div className="info-text">鉴定日期: {authDate}</div>
            <div className="info-text">证书编号: {certId}</div>
            <div className="info-text">鉴定方: 较真App</div>
          </div>

          {/* 
          <div className="qr-code-wrapper">
                <div className="qr-code"></div>
                <div className="scan-code-text">扫码查看详情</div>
          </div> */}
        </div>

        <div className="footer">
          <div>最终结论以实物为准，在线鉴定结论无法参与司法流程</div>
          <div>本机构作为第三方机构，不参与买卖纠纷，并保留最终解释权</div>
        </div>
      </div>
    </div>
  )
}

export default Certificate
