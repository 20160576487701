import { useState } from "react"

const useToggle: (initValue: Boolean) => [
  any,
  {
    set: Function
    toggle: Function
  }
] = (initValue = false) => {
  const [value, setValue] = useState(initValue)
  return [
    value,
    {
      set: setValue,
      toggle: (e) => {
        e.preventDefault()
        setValue((flag) => !flag)
      },
    },
  ]
}

export default useToggle
