import React from "react"
import HeroAbout from "../AboutUs/HeroAbout"
import BackToTop from "../BackToTop"
import FaqHomeOne from "../Home/FaqHomeOne"
import FooterHomeOne from "../Home/FooterHomeOne"
import HeroHomeOne from "../Home/HeroHomeOne"
import HomeOneHeader from "../Home/HomeOneHeader"
import ServicesHomeOne from "../Home/ServicesHomeOne"
import HeroService from "../Service/HeroService"
import "./AuthenticateGuide.less"

const AuthenticateGuide = () => {
  const prewords = [
    "下单并成功提交图片后，一般情况下鉴定师会在2小时内给予反馈，如遇节假日或公众假期会稍有延长。如图片拍摄高清到位会直接出结果，否则需要补图配合；",
    "每张订单仅鉴定一个商品，无法提供的附件可以跳过并文字留言告知鉴定师；",
    "为避免造假商家套取正品鉴定点，鉴定神器及旗下鉴定师只负责给予鉴定结果，一律不负责告知对比鉴定细节；",
    "晚上22：30后下单可能会顺延至次日上午10：00开始鉴定，有不清晰的地方请联系在线客服协助；",
    "为了保证鉴定结果的准确性，请尽可能参照示例图以及鉴定师的要求再光线充足的环境下将鉴定物品的细节拍摄清晰；",
    "关于退单退款：鉴定师接单后不接受退款申请。鉴定师接单前如有特殊情况需要退单请联系客服；",
    "每张订单均会出具电子鉴定证书，点击证书可保存使用。鉴定通过则说明符合品牌正品工艺，可放心使用，鉴定不通过为非正品，可将证书发给卖家要求退货退款",
  ]

  const appraiserQualities = [
    "中检北京认证鉴定师",
    "中检上海认证鉴定师",
    "日本奢侈品协会认证鉴定师",
    "公检法特邀鉴定师",
    "超20年奢侈品鉴定经验",
  ]

  const steps = [
    "选择鉴定物品类，目前支持箱包/首饰/服饰/鞋履/腕表/围巾/眼镜/腰带/口红",
    "选择鉴定物品牌",
    "按需选择鉴定套餐并下单支付",
    "按照指引拍摄细节图或从手机相册选择已有图片上传提交",
    "如有需要，按鉴定师进一步指引补充细节图片",
    "获得鉴定证书",
  ]

  return (
    <>
      <div className="insturction-contents">
        <h2>鉴定前须知</h2>
        <ul>
          {prewords.map((w, i) => (
            <li key={i}><b>»</b> {w}</li>
          ))}
        </ul>
        <h2>专业鉴定师团队</h2>
        <ul>
          {appraiserQualities.map((w, i) => (
            <li key={i}><b>»</b> {w}</li>
          ))}
        </ul>
        <h2>使用流程</h2>
        <ol>
          {steps.map((w, i) => (
            <li key={i}>{i+1}. {w}</li>
          ))}
        </ol>{" "}
      </div>
    </>
  )
}

export default AuthenticateGuide
