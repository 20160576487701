import React from "react"
import useToggle from "../../Hooks/useToggle"
import BackToTop from "../BackToTop"
import FooterHomeOne from "../Home/FooterHomeOne"
import HomeOneHeader from "../Home/HomeOneHeader"
import Drawer from "../Mobile/Drawer"
import HeroAbout from "./HeroAbout"

function AboutUs() {
  const [drawer, drawerAction] = useToggle(false)
  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      {/* <HeaderAbout drawer={drawer} action={drawerAction.toggle} /> */}
      <HomeOneHeader />
      <HeroAbout />
      {/* <ServicesAbout /> */}
      {/* <TeamAbout /> */}
      {/* <SponserHomeTwo /> */}
      {/* <SignupHomeEight /> */}
      {/* <FooterHomeOne className="appie-footer-about-area" /> */}
      <FooterHomeOne />
      <BackToTop />
    </>
  )
}

export default AboutUs
