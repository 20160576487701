import { Button } from "antd"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import "./App.less"
import AuthCert from "./pages/qr/components/AuthCert/AuthCert"
import MainFrame from "./components/MainFrame/index"
import ScrollToTop from "./components/Helper/ScrollToTop"
import Tos from "./components/HtmlContent/tos"
import PrivacyPolicy from "./components/HtmlContent/privacy_policy"
import HomeOne from "./components/Home"
import Service from "./components/Service/index"
import AboutUs from "./components/AboutUs/index"
import Contact from "./components/Contact/index"
import Error from "./components/Error/index"
import AuthenticateGuide from "./components/AuthenticateGuide/AuthenticateGuide"
import { useEffect } from "react"
import axios from "axios"
import API from "./api/api"
import QRContent from "./pages/qr/index"
import ShopItem from "./pages/Shop/ShopItem"

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<HomeOne />} />
          <Route
            path="/app_privacy_policy"
            element={
              <MainFrame>
                <PrivacyPolicy />
              </MainFrame>
            }
          />
          <Route
            path="/app_tos"
            element={
              <MainFrame>
                <Tos />
              </MainFrame>
            }
          />
          {/* <Route path="/news" element={<News />} /> */}
          {/* <Route path="/news/single-news" element={<SingleNews />} /> */}
          <Route path="/app" element={<Navigate to={"/"} replace={true} />} />
          <Route path="/authenticate-guide" element={<AuthenticateGuide />} />
          <Route path="/shop" element={<ShopItem />} />
          <Route path="/qr" element={<QRContent />} />
          {/* <Route path="/service" element={<Service />} /> */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/error" element={<Error />} />
          <Route path="/AUN-:id" element={<AuthCert />} />
          <Route element={<Error />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App
