import React, { useRef } from "react"
import Slider from "react-slick"
// import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import user from "../../assets/images/testimonial-user.png"

function TestimonialHomeOne() {
  const sliderRef = useRef()
  const sliderNext = () => {
    // @ts-expect-error
    sliderRef.current.slickNext()
  }
  const sliderPrev = () => {
    // @ts-expect-error
    sliderRef.current.slickPrev()
  }

  const testimonials = [
    {
      avatar:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Finews.gtimg.com%2Fnewsapp_match%2F0%2F9747028589%2F0.jpg&refer=http%3A%2F%2Finews.gtimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642496693&t=d6eefd16fdc3a67f6049f8cf7ce1091c",
      name: "Joycelyn",
      subtitle: "LV水桶包",
      comment: "在这里买了一个LV水桶包，成色比想象中新好多，还有电子鉴定证书，服务不错会回购！",
      stars: 5,
    },
    {
      avatar:
        "https://cdn.hk01.com/di/media/images/dw/20200812/370289758980673536.jpeg/n9DPEMxK9oiurOikNCKS0vCbRRiCES0RUTjuFlE47hY?v=w1920",
      name: "大星星",
      subtitle: "精细鉴定",
      comment: "选了99的精细鉴定，鉴定师很耐心追着要补图，鉴定了19个部位最后还有一张证书，代购买的终于放下心头大石!",
      stars: 5,
    },
    {
      avatar: "https://p1.nanmuxuan.com/images/db/0314284db85055d3.jpg",
      name: "Irene王碧尔",
      subtitle: "Chanel Hilton",
      comment: "直播间拍的包包跟主播展示的一致，几千块买了一个中古香奈儿希尔顿，感觉自己赚到了哈哈哈!",
      stars: 5,
    },
    {
      avatar:
        "https://images.chinatimes.com/newsphoto/2021-03-19/656/20210319003325.jpg",
      name: "嘉轩",
      subtitle: "Gucci酒神包",
      comment: "给女朋友选的礼物，看到是全新带膜的还承诺假一赔三没有犹豫就拍了，反正她收到挺开心的!",
      stars: 5,
    },
  ]

  return (
    <section className="appie-testimonial-area pt-100 pb-160" id="testimonial">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div
              className="appie-testimonial-slider"
              style={{ position: "relative" }}
            >
              <span
                className="prev slick-arrow"
                style={{ display: "block" }}
                onClick={sliderNext}
                role="button"
              >
                <i className="fal fa-arrow-left" />
              </span>
              <Slider ref={sliderRef} dots arrows={false}>
                {testimonials.map((t, i) => (
                  <div key={i} className="appie-testimonial-item text-center">
                    <div className="author-info">
                      {/* <img
                        src={t.avatar}
                        alt=""
                        style={{
                          height: 64,
                          width: 64,
                          backgroundColor: "rgba(255,255,255,0.3)",
                          objectFit: "cover",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      /> */}
                      <h5 className="title">{t.name}</h5>
                      <span>{t.subtitle}</span>
                    </div>
                    <div className="text">
                      <p>{t.comment}</p>
                      <ul>
                        {Array(t.stars)
                          .fill("")
                          .map((s, i) => (
                            <li key={i}>
                              <i className="fas fa-star" />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </Slider>
              <span
                onClick={sliderPrev}
                role="button"
                className="next slick-arrow"
                style={{ display: "block" }}
              >
                <i className="fal fa-arrow-right" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialHomeOne
