import React, { useEffect, useRef, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import axios from "axios"
import HomeOneHeader from "../../components/Home/HomeOneHeader"
import FooterHomeOne from "../../components/Home/FooterHomeOne"
import API from "../../api/api"
import "./ShopItem.less"
import { Button, Col, Modal, Row, Skeleton } from "antd"
import _get from "lodash/get"
import { isWechatBrowser, qiniuImageView } from "../../utils/helper"
import { BrowserView, MobileView, isIOS, isAndroid } from "react-device-detect"

const ShopItem = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const itemId = searchParams.get("id")
  const [downloadLinks, setDownloadLinks] = useState()
  const [shopItem, setShopItem] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const fetchShopItem = async (shopItemId: number) => {
    setIsLoading(true)

    try {
      const res = await axios.get(
        "https://cloud.breal.co/webapi/shop/shopitem/" + shopItemId
      )
      setShopItem(res.data)

      let name = _get(res, "data.name")
      let description = _get(res, "data.description")
      if (name) {
        document.title = name + " - 较真APP"
        // @ts-expect-error
        document.head.children.namedItem("description").content = description
      }
      setIsLoading(false)
    } catch (err) {}
  }

  useEffect(() => {
    fetchAppDownloadLinks()
    if (itemId && parseInt(itemId)) {
      fetchShopItem(parseInt(itemId))
    }
  }, [])

  const fetchAppDownloadLinks = async () => {
    try {
      const res = await API.getDownloadLinks()
      setDownloadLinks(res)
    } catch (err) {
      console.error(err)
    }
  }

  const nImages = _get(shopItem, "photos", []).length || 6

  let downloadLink
  if (isAndroid) {
    downloadLink = _get(downloadLinks, "android", "#")
  } else if (isIOS) {
    downloadLink = _get(downloadLinks, "ios", "#")
  } else {
    downloadLink = "/"
  }

  return (
    <>
      <HomeOneHeader />
      <div className="shop-item-wrapper">
        {isLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              padding: 24,
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                backgroundColor: "black",
                opacity: 0.05,
                width: "100%",
                aspectRatio: "2",
                marginBottom: 16,
                borderRadius: 16,
              }}
            />
            <Skeleton loading={isLoading} title />
            <Skeleton loading={isLoading} title />
          </div>
        ) : (
          <Row gutter={16}>
            <Col md={24}>
              <div>
                <div className="image-list">
                  <div
                    className="image-grid"
                    style={{
                      width: `${nImages * 70}%`,
                    }}
                  >
                    {_get(shopItem, "photos", []).map((p, i) => (
                      <img
                        key={i}
                        className="shop-item-img"
                        src={qiniuImageView(_get(p, "file.url"), 640)}
                      />
                    ))}
                  </div>
                </div>
                <div className="content">
                  <div className="brand">
                    {_get(shopItem, "brand.name", "")} /
                    {_get(shopItem, "brand.cn_name", "")}
                  </div>
                  <h1>{_get(shopItem, "name")}</h1>
                  <div className="description">
                    {_get(shopItem, "description")}
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="condition">
                      成色:{" "}
                      {_get(shopItem, "condition", 0) == 100
                        ? "全新未使用"
                        : _get(shopItem, "condition") + "新"}
                    </div>
                    <div style={{ width: 8 }}></div>
                    <div className="condition">
                      编号: {_get(shopItem, "id", 0)}
                    </div>
                  </div>
                  <div className="price">
                    ¥{_get(shopItem, "price", 0) / 100}
                  </div>
                  <div className="search-id">
                    较真APP内搜索ID #<span>{_get(shopItem, "id")}</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <div className="btns-container" style={{ textAlign: "center" }}>
          <Button
            className="download-btn"
            size="large"
            style={{ marginRight: 16 }}
            href={downloadLink}
          >
            下载较真APP
          </Button>
          <Button
            type="primary"
            size="large"
            className="open-app-btn"
            onClick={() => {
              if (isWechatBrowser()) {
                Modal.info({
                  maskClosable: true,
                  icon: null,
                  okButtonProps: {
                    style: { display: "none" },
                  },
                  content: (
                    <div>
                      <div
                        style={{
                          fontSize: "1.4em",
                          textAlign: "center",
                        }}
                      >
                        点击右上角在浏览器打开
                      </div>

                      <div
                        style={{
                          textAlign: "center",
                          marginTop: 24,
                        }}
                      >
                        <img
                          src="https://file.ilux.ai/6SpHm8UR58tv7kfXYdUVBxHicDRNNeVB/app-icon-216.png"
                          style={{
                            width: 80,
                            height: 80,
                            borderRadius: 18,
                            marginBottom: 6,
                            boxShadow: "0 4px 6px rgba(0,0,0,0.14)",
                          }}
                        />
                        <div>较真bReal</div>
                      </div>
                    </div>
                  ),
                })
              } else {
                if (isIOS) {
                  try {
                    let appUri = "breal://breal.co/shop?id=" + itemId
                    window.location.href = appUri

                    setTimeout(() => {
                      window.location.href =
                        "https://apps.apple.com/cn/app/%E8%BE%83%E7%9C%9FbReal-%E6%AD%A3%E5%93%81%E4%BA%8C%E6%89%8B%E5%A5%A2%E4%BE%88%E5%93%81/id1587977803"
                    }, 2000)
                  } catch (err) {}
                } else if (isAndroid) {
                  try {
                    let appUri = "https://breal.co/shop?id=" + itemId
                    window.location.href = appUri
                  } catch (err) {}
                }
              }
            }}
          >
            去较真APP购买
          </Button>
        </div>
      </div>
      <FooterHomeOne downloadLinks={downloadLinks} />
    </>
  )
}

export default ShopItem
