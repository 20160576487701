import React, { useEffect } from "react"
import "./tos_privacy.less"

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "较真 - 用户隐私政策"
  }, [])

  return (
    <div className="html-content">
      <div className="content">
        <h2 id="较真用户隐私政策">较真用户隐私政策</h2>

        <h3 id="引言">引言</h3>
        <p>
          较真（以下简称为“我们”）尊重并保护所有较真用户的个人信息及隐私安全。为同时给您提供更准确、有个性化的服务和更安全的互联网环境，我们依据《中华人民共和国网络安全法》《中华人民共和国个人信息保护法》《中华人民共和国民法典》《信息安全技术个人信息安全规范》以及其他相关法律法规和技术规范明确了我们收集/使用/对外提供个人信息的原则，进一步阐述了关于您个人信息的相关权利。
        </p>
        <p>
          本政策与您所使用的我们的产品与/或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品与/或服务（以下统称“使用我们的产品与/或服务”）时，我们将按照本政策的约定处理和保护您的个人信息。我们尽量以简明扼要的表述向您解释本政策所涉及的技术词汇，以便于您理解。{" "}
          <strong>
            本政策中与您权益（可能）存在重大关系的条款，我们已使用加粗字体予以区别，请您重点查阅。
          </strong>
        </p>
        <p>
          <strong>
            较真特别提请未满18周岁的未成年（特别是未满十四周岁的儿童）及其监护人的注意，我们已明确未成年保护专门章节（以粗体提示），请您仔细阅读。
          </strong>
        </p>
        <p>
          请在使用/继续使用我们的各项产品与服务前，仔细阅读并充分理解本政策，并在需要时，按照本政策的指引，做出适当的选择。如果您不同意本政策的内容，将可能导致我们的产品与/或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用我们提供的产品与/或服务的行为，都表示您充分理解和同意本《较真用户隐私政策》（包括更新版本）的全部内容。
        </p>
        <h3 id="关于我们">关于我们</h3>
        <p>较真网站及APP的经营者为广州爱丽社科技有限公司及其关联公司。</p>
      
        <h3 id="目录">目录</h3>
        <p>
          <a href="#%E4%B8%80%E3%80%81%E6%88%91%E4%BB%AC%E5%A6%82%E4%BD%95%E6%94%B6%E9%9B%86%E5%92%8C%E4%BD%BF%E7%94%A8%E6%82%A8%E7%9A%84%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF">
            一、我们如何收集和使用您的个人信息
          </a>
          <br />
          <a href="#%E4%BA%8C%E3%80%81%E6%88%91%E4%BB%AC%E5%A6%82%E4%BD%95%E5%85%B1%E4%BA%AB%E3%80%81%E8%BD%AC%E8%AE%A9%E3%80%81%E5%85%AC%E5%BC%80%E6%8A%AB%E9%9C%B2%E6%82%A8%E7%9A%84%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF">
            二、我们如何共享、转让、公开披露您的个人信息
          </a>
          <br />
          <a href="#%E4%B8%89%E3%80%81%E6%88%91%E4%BB%AC%E5%A6%82%E4%BD%95%E5%AD%98%E5%82%A8%E5%92%8C%E4%BF%9D%E6%8A%A4%E6%82%A8%E7%9A%84%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF">
            三、我们如何存储和保护您的个人信息
          </a>
          <br />
          <a href="#%E5%9B%9B%E3%80%81%E6%82%A8%E5%A6%82%E4%BD%95%E7%AE%A1%E7%90%86%E6%82%A8%E7%9A%84%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF">
            四、您如何管理您的个人信息
          </a>
          <br />
          <a href="#%E4%BA%94%E3%80%81%E6%82%A8%E5%A6%82%E4%BD%95%E6%B3%A8%E9%94%80%E6%82%A8%E7%9A%84%E8%B4%A6%E5%8F%B7">
            五、您如何注销您的账号
          </a>
          <br />
          <a href="#%E5%85%AD%E3%80%81%E6%9C%89%E5%85%B3%E7%AC%AC%E4%B8%89%E6%96%B9%E6%8F%90%E4%BE%9B%E4%BA%A7%E5%93%81%E5%92%8C%E6%88%96%E6%9C%8D%E5%8A%A1%E7%9A%84%E7%89%B9%E5%88%AB%E8%AF%B4%E6%98%8E">
            六、有关第三方提供产品和/或服务的特别说明
          </a>
          <br />
          <a href="#%E4%B8%83%E3%80%81%E6%88%91%E4%BB%AC%E5%A6%82%E4%BD%95%E4%BD%BF%E7%94%A8cookie%E5%92%8C%E5%85%B6%E4%BB%96%E5%90%8C%E7%B1%BB%E6%8A%80%E6%9C%AF">
            七、我们如何使用Cookie和其他同类技术
          </a>
          <br />
          <a href="#%E5%85%AB%E3%80%81%E6%9C%AA%E6%88%90%E5%B9%B4%E4%BA%BA%E4%BF%9D%E6%8A%A4">
            八、未成年人保护
          </a>
          <br />
          <a href="#%E4%B9%9D%E3%80%81%E6%88%91%E4%BB%AC%E5%A6%82%E4%BD%95%E6%9B%B4%E6%96%B0%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96">
            九、我们如何更新隐私政策
          </a>
          <br />
          <a href="#%E5%8D%81%E3%80%81%E5%A6%82%E4%BD%95%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC">
            十、如何联系我们
          </a>
          <br />
          <a href="#%E5%8D%81%E4%B8%80%E3%80%81%E5%85%B6%E4%BB%96">
            十一、其他
          </a>
          <br />
        </p>
        <h4 id="一、我们如何收集和使用您的个人信息">
          一、我们如何收集和使用您的个人信息
        </h4>
        <p>
          <strong>您理解并同意：</strong>
        </p>
        <p>
          较真是正品二奢平台，较真以“Friends don't let friends wear fake”为使命，用户可以通过在线图文或实物鉴定方式鉴定你的物品。我们将在本政策中依次向您说明每项功能可能收集的个人信息范围、收集目的、收集方式，以及拒绝提供个人信息可能导致的结果。请您知悉，您拒绝提供各项功能实现所必要的对应信息时，仍然可以使用较真App的其他功能。
        </p>
        <p>
          我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用我们服务过程中主动提供或因使用我们产品和/或服务而产生的个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将已经收集的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
        </p>
        <ol>
          <li>
            <p>
              <strong>账号注册及登录</strong>
            </p>
            <p>
              {" "}
              1.1.
              当您注册及登录时，如您使用一键登录的功能，基于我们与通信运营商的合作，我们会收集您的【
              <strong>手机号码和手机运营商信息】</strong>
              ，以便为您提供快捷的登录服务；同时，为帮助您完成注册过程，我们还将收集您的
              【<strong>兴趣】</strong>，以为您提供较真初始服务。
            </p>
            <p>
              {" "}
              1.2.{" "}
              <strong>
                如您使用第三方账号进行登录，为了满足网络实名制要求，在您使用发布、评论及其他要求实名认证的功能与服务前，我们将另行收集您的手机号码以完成实名认证
              </strong>{" "}
              。请您谨慎考虑后提供这类信息，若您拒绝提供，您可能无法正常使用相应的功能。
            </p>
            <p>
              {" "}
              1.3.
              您还可以根据自身需求选择填写或更改性别、出生年月日、教育经历（院校及入学时间）、地区及个人介绍来完善您的信息，但如您不提供此类信息，不会影响您使用本产品和相关服务。
            </p>
            <p>
              {" "}
              1.4.
              如果您成功注册较真账号，我们会在较真应用程序及网站中显示您的用户名、头像、地区、个人介绍信息。
            </p>
            <p>
              {" "}
              1.5.
              在使用“较真”客户端应用程序及相关网站为您提供的服务时，您在“较真”客户端应用程序注册的账号
              可同时登录“较真视频版”（暂定，具体名称以上线时标注的为准，以下简称“较真视频版”）及其他不同版本
              客户端应用程序并获得较真视频版相关服务、使用相关功能，但部分使用第三方账号注册的情形除外。您使用
              该账号登录、使用较真视频版的，应同时受《“较真视频版”用户服务协议》、《“较真视频版”隐私政策》
              及相关协议条款的约束。
            </p>
          </li>
          <li>
            <p>
              <strong>向您提供产品和/或服务</strong>
            </p>
            <p>
              {" "}
              <strong>2.1. 信息浏览、发布、点赞、收藏、分享功能。</strong>
            </p>
            <p>
              {" "}
              2.1.1.为了向您提供我们最核心的信息展示服务并向您推荐可能感兴趣的笔记及相关信息，我们会收集
              <strong>
                您的性别、生日、浏览记录（您的关注、浏览）和点击操作记录（兴趣、点击、搜索、点赞、收藏、分享、评论、发布记录及有关行为）。若您希望关闭我们基于有关信息向您进行的推荐，您可参照本政策2.14条进行操作。
              </strong>
            </p>
            <p>
              {" "}
              2.1.2.当您使用发布、编辑内容功能时，我们会请求您授权存储（相册、媒体和其他文件）、相机（摄像头）、麦克风（录音）权限。您如果拒绝授权提供，将无法使用相应功能，但不影响您正常使用较真的其他功能。
            </p>
            <p>
              {" "}
              2.1.3.当您使用发布、评论、点赞、收藏、分享功能时，
              <strong>
                您发布的文字、照片、视频、音频、评论、点赞、收藏、分享记录信息会存储在我们的服务器中，
              </strong>
              因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。
            </p>
            <p>
              {" "}
              2.2.安全运行与风控验证。为了保障软件与服务的安全运行，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们会收集您的
              设备信息【包括：设备型号、设备名称、SIM卡序列号、
              <strong>
                设备唯一标识符（包括：IMEI、IMSI、AndroidID、IDFA）
              </strong>
              】、地理位置、存储（相册、媒体和其他文件）、浏览器类型和设置、语言设置、操作系统和应用程序版本、网络设备硬件地址、登录IP地址、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号以及
              <strong>软件使用记录信息</strong>
              ，如您不同意我们收集前述信息，可能无法完成风控验证。请您理解，为动态检测您的账户是否存在异常登录，每次您把较真切换至后台或重新启动程序时，我们可能会再次读取，我们将把读取频次控制在合理范围内。
            </p>
            <p>
              {" "}
              2.3.搜索功能。当您使用较真搜索功能时，我们会收集
              <strong>
                您查询的关键词、阅读记录和访问时间、评论和互动记录。
              </strong>
              收集此信息可以向您提供您所需要的内容和可能更感兴趣的服务，同时亦可以改进我们的产品和服务。
            </p>
            <p>
              {" "}
              2.4.定位功能。当您开启设备定位功能并使用较真基于地理位置提供的相关服务（含内容推荐、内容发布、购物编辑收货信息、资料编辑）时，我们可能会通过弹窗获得您的单独同意后收集有关
              <strong>
                您的地理位置信息。地理位置信息属于个人敏感信息，拒绝提供该信息您将无法使用上述基于位置提供的相关服务
              </strong>
              ，但不影响您正常使用较真的其他功能。
            </p>
            <p> 2.5. 互动功能与服务。</p>
            <p>
              {" "}
              2.5.1.当您关注您感兴趣的账号、进行浏览、评论、点赞、收藏、分享内容时，我们会收集
              <strong>您关注的账号和前述信息</strong>
              ，并向您展示您关注账号发布的内容；同时，您点赞或收藏的内容会向您的粉丝展示，您可将收藏的内容设置仅自己可见，可以在【我】-【收藏】-【收藏专辑】中开启仅自己可见。
            </p>
            <p>
              {" "}
              2.5.2.当您使用推荐通讯录好友功能或您主动发现好友时，在获得您明示同意后，我们会收集
              <strong>您的通讯录信息</strong>
              ，用于向您展示通讯录中的好友，您的好友也能看到您使用较真服务。
              <strong>
                如果您不希望被推荐，可以在【我】-点击左上角-【设置】-【隐私设置】-【不把我推荐给可能认识的人】中选择关闭。通讯录信息属于个人敏感信息，拒绝提供该信息仅会使您无法使用上述功能
              </strong>
              ，但不影响您正常使用较真及相关服务的其他功能。
            </p>
            <p>
              {" "}
              2.5.3.当您购买商品或服务时，如您需要编辑收货信息，
              <strong>我们可能会向您请求通讯录权限</strong>
              ，通讯录权限是敏感权限，拒绝提供仅会使您无法通过通讯录编辑收货信息，但不影响其他功能的使用。
            </p>
            <p>
              {" "}
              2.5.4.当您选择使用扫码添加好友时，
              <strong>我们可能会向您请求相机（摄像头）权限</strong>
              ，相机（摄像头）权限是敏感权限，拒绝提供仅会使您无法通过扫码途径添加好友，不影响其他功能的使用。
            </p>
            <p>
              {" "}
              2.6.
              购买、查询、使用虚拟财产。为了方便您查询虚拟财产，并尽量降低可能存在的风险，
              【<strong>钱包】会记录您的充值、余额与使用情况</strong>。
            </p>
            <p> 2.7. 身份认证功能及账号找回。</p>
            <p>
              {" "}
              2.7.1.当您使用身份认证功能申请成为认证用户时，我们可能会收集您的身份信息，包括
              <strong>
                您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的个人身份。若您拒绝提供，不会影响较真其他功能的正常使用
              </strong>
              。
            </p>
            <p>
              {" "}
              2.7.2.当您使用账号找回功能时，我们可能会收集您的身份信息，包括您的真实姓名、
              <strong>身份证件号码或照片、面部识别信息</strong>
              ，用于证实您的身份，便于您找回较真账号。
              <strong>若您拒绝提供，不会影响较真其他功能的正常使用</strong>。
            </p>
            <p>
              {" "}
              2.7.3.当您申请开通直播功能时，根据法律法规的要求，我们可能会收集您的身份信息，包括
              <strong>
                您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的个人身份。若您拒绝提供，不会影响较真其他功能的正常使用
              </strong>
              。
            </p>
            <p>
              {" "}
              2.7.4.当您申请商品合作功能时，根据法律法规的要求，我们可能会收集您的身份信息，包括
              <strong>
                您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的个人身份；为了帮助您顺利完成商品合作，我们可能收集您的手机号或微信号。若您拒绝提供，不会影响较真其他功能的正常使用
              </strong>
              。
            </p>
            <p>
              {" "}
              2.7.5.当您申请品牌合作功能时，为了验证您的个人身份并判断您是否符合使用品牌合作功能的准入条件，我们可能会收集
              <strong>
                您的真实姓名、身份证件号码或照片、较真账号信息、职业、学历以及收入明细信息。若您拒绝提供，不会影响较真其他功能的正常使用
              </strong>
              。
            </p>
            <p>
              {" "}
              2.8.商品购买功能。您可以在较真商城订购商品/服务。为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供物流服务，在您下单交易或为了完成交易时，我们会收集您的
              <strong>
                交易商品/服务信息、订单号、下单时间、交易金额、支付方式、收货人姓名、地址、联系电话、物流信息
              </strong>
              。对于部分特殊类型的商品和服务，您还需要提供该商品或服务所必需的其他信息，
              <strong>
                例如涉及跨境交易时您需要提供您的真实姓名、身份证件号码以及身份证件正反面照片以完成清关
              </strong>
              。
            </p>
            <p>
              {" "}
              2.9.当您申请开通数字藏品功能时，我们可能会收集您的身份信息，包括
              <strong>
                您的真实姓名、身份证件号码或照片、面部识别信息，用于证实您的个人身份
              </strong>
              。
            </p>
            <p>
              {" "}
              2.10.客服和售后服务功能。当您联系我们的客服或提出我们的产品与/或服务的售后申请时，我们系统可能会记录您与客服之间的
              <strong>通讯记录</strong>
              。为了您的账号与系统安全，我们可能需要您提供相关个人信息与您之前提供的个人信息相匹配以验证您的用户身份。验证成功后，我们可能会收集您与我们的沟通信息（账号信息、
              <strong>订单信息</strong>
              、您为了证明相关事实而提供的图片/视频/文字信息及有关信息）、您的联系方式以及您与我们的
              <strong>沟通记录</strong>，包括
              <strong>线上沟通记录、电话录音</strong>
              ，以便尽快为您解决问题和改进我们的产品与/或服务。同时，我们可能会以电话、短信、站内信及相关方式主动联系您，沟通您在使用我们产品及服务过程中产生的问题。
            </p>
            <p>
              {" "}
              为了保证服务的体验、处理服务纠纷，您与客服间的通话信息可能会被录音。有关上述信息的收集和使用规则如下：
            </p>
            <p>
              {" "}
              2.10.1.当您使用较真服务与平台或者商家发生纠纷时，我们可调取并使用
              <strong>录音信息</strong>作为解决纠纷的参考依据。
            </p>
            <p>
              {" "}
              2.10.2.为了向您提供客服和售后服务，我们可能将您
              <strong>的通话录音</strong>
              存储在第三方客服系统中，我们会通过协议约束等方式，确保第三方无法访问您的
              <strong>通话录音</strong>
              ；除以下情形外，平台不会将录音提供给任何人（包括通话主、被叫方）：a.有权机关依法定程序调取；b.被录音的一方持司法机关出具的法律文件依法调取。
            </p>
            <p>
              {" "}
              2.11.支付功能。您可以在较真购买商品/服务以及较真会员服务。在您使用该功能的过程中可能会需要进行支付，在支付过程中，我们可能会收集
              <strong>
                您的第三方支付账号（支付宝账号、微信账号、银联账号及有关账号）
              </strong>
              。
            </p>
            <p>
              {" "}
              2.12.信息预填写功能。您在较真平台填写表单或订单信息时，为帮您节省填写时间，我们会根据您注册、购物时向平台提供的姓名、性别（如有）、手机号码、地区（如有）帮您自动填充表单信息。
            </p>
            <p>
              {" "}
              2.13.测肤、试妆功能。当您使用较真提供的测肤或试妆功能时，我们可能需要
              <strong>
                在通过弹窗征得您单独同意后收集您的存储（相册、媒体和其他文件）、相机（摄像头）权限或面部信息以为您提供该项服务。但我们在提供测肤和试妆服务后不会存储您的面部特征信息。若您拒绝提供前述面部特征信息，您将无法体验我们的测肤、试妆功能，但不会影响较真其他功能的正常使用
              </strong>
              。
            </p>
            <p>
              {" "}
              请注意，由于上述<strong>面部特征信息</strong>
              直接关系到您的人身和财产安全，因此您在向我们提供时需慎重考虑。我们将仅基于为您提供测肤、试妆服务的目的收集处理您的上述
              <strong>面部特征信息</strong>
              ，而个人信息的处理将仅影响您所体验的测肤、试妆功能的结果。
            </p>
            <p>
              {" "}
              2.14.为保证您在使用较真平台服务时能够与后台服务器保持即时通信、且您能够及时收到个性化内容推送，我们会{" "}
              <strong>
                i）向您获取及调用您的开机广播权限用于启动较真或其关联公司移动客户端软件，ii）采用第三方消息推送服务为您生成标识ID用于区分您的设备的唯一性，iii）读取手机状态，用于判断较真软件处在前台还是后台，以提升推送服务的时效性。
              </strong>
            </p>
            <p>
              {" "}
              2.15.为向您展示个性化的笔记内容，确保您能获知更为适宜的内容，我们可能会收集在
              <strong>
                您在较真平台上搜索/播放/展示推荐内容的记录、音视频播放记录
              </strong>
              、性别、生日。如您对推送内容不感兴趣或希望减少某些信息推送时，您可以在长按笔记内容，点击“不感兴趣”。您也可选择关闭推送信息，具体可前往【我】-点击左上角-【设置】-【隐私设置】-【个性化选项】关闭个性化推荐机制。
            </p>
            <p>
              {" "}
              2.16.为向您提供更便捷、更符合您个性化需求的商品信息展示、搜索及推送服务，我们会根据您的{" "}
              <strong>
                设备信息（包括2.2条所述设备信息及移动应用列表信息）
              </strong>{" "}
              和您使用较真客户端时的 <strong>浏览使用信息、购买记录，</strong>{" "}
              提取您的偏好特征，并基于特征标签产出间接人群画像，用于向您展示、推送信息和可能的商业广告{" "}
              <strong>
                (请您理解，单独的设备信息无法识别特定自然人的身份信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化处理）
              </strong>
              。对于您在使用我们产品或服务的过程中提供的您的联系方式（电话、邮箱及其他联系方式），我们在运营中可能会以短信的方式，为您提供您可能感兴趣的服务，功能或活动及相关商业性信息。我们努力保障您的浏览体验。如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或提供的其他方式进行退订或关闭。
            </p>
            <p>
              {" "}
              2.17．在您分享或被分享信息、参加活动情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以为您实现跳转、分享、活动联动功能或服务。
            </p>
            <p> 2.18.设备权限调用汇总</p>
            <p>
              {" "}
              我们对较真在提供服务过程中，会调用您的主要设备权限汇总如下。您可以在设备的设置功能中选择关闭部分或全部权限。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引：
            </p>
          </li>
        </ol>
        <table>
          <thead>
            <tr>
              <th>
                <strong>设备权限</strong>
              </th>
              <th>
                <strong>对应业务功能</strong>
              </th>
              <th>
                <strong>功能场景说明</strong>
              </th>
              <th>
                <strong>是否可关闭</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>麦克风（录音）</strong>
              </td>
              <td>
                1.内容发布
                <br />
                2.直播
                <br />
                3.消息和聊天
              </td>
              <td>
                1.发布内容时进行录音
                <br />
                2.开启直播时进行录音
                <br />
                3.
                与其他用户聊天时发送语音消息、向商城在线客服发布短视频内容时进行录音
              </td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>通讯录</strong>
              </td>
              <td>
                1.发现或推荐好友
                <br />
                2.购物时编辑收货信息
              </td>
              <td>
                1.发现或推荐通讯录中的好友
                <br /> 2.用户购买商品或服务时，编辑收货信息
              </td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>通知</strong>
              </td>
              <td>系统通知</td>
              <td>APP相关消息推送</td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>网络权限</strong>
              </td>
              <td>上网功能</td>
              <td>开启上网功能，实时读取与更新</td>
              <td>可以，关闭后APP内容无法实时读取与更新，无法正常使用APP</td>
            </tr>
            <tr>
              <td>
                <strong>相机（摄像头）</strong>
              </td>
              <td>
                1.直播
                <br />
                2.内容笔记
                <br />
                3．测肤、试妆功能
                <br />
                4.扫一扫
                <br />
                5. 消息
              </td>
              <td>
                1.开启直播时进行拍摄
                <br />
                2.发布内容时进行拍摄
                <br />
                3.使用测肤、试妆功能时进行拍摄
                <br />
                4.添加好友或活动相关二维码扫描
                <br />
                5. 与其他用户聊天时进行拍摄
              </td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>日历</strong>
              </td>
              <td>
                1. 直播活动提醒
                <br />
                2. 聊天活动提醒
              </td>
              <td>提醒直播和聊天活动</td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>悬浮窗</strong>
              </td>
              <td>
                1.直播
                <br />
                2.聊天
              </td>
              <td>将直播、聊天画面悬浮于其他内容上</td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>地理位置</strong>
              </td>
              <td>
                1.内容推荐
                <br />
                2.内容发布
                <br />
                3.购物时编辑收货信息
                <br />
                4.资料编辑
              </td>
              <td>
                1.根据用户所在位置推荐内容，如附近功能
                <br />
                2.用户发布内容时定位所在区域，如用户使用含定位的贴纸、发布定位标签、使用标记功能
                <br />
                3.用户购买商品或服务时，编辑收货信息
                <br />
                4.个人页编辑地区
              </td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>存储（相册、媒体和其他文件）</strong>
              </td>
              <td>
                1.注册认证
                <br />
                2.内容发布
                <br />
                3.内容保存
                <br />
                4.直播
                <br />
                5.测肤、试妆
                <br />
                6.消息
                <br />
                7.分享
                <br />
                8.资料编辑
                <br />
                9.意见反馈
                <br />
                10.扫一扫
              </td>
              <td>
                1.注册和认证时上传照片
                <br />
                2.发布内容时读取相册和媒体内容、进行存储
                <br />
                3.日志信息记录、信息缓存
                <br />
                4.直播：上传照片、下载直播内容、观看本地视频
                <br />
                5.使用测肤、试妆功能时
                <br />
                6.消息：私信下载、与商城在线客服沟通时读取相册内容
                <br />
                7.截图分享
                <br />
                8.编辑资料选择头像图片时读取相册内容
                <br />
                9.用“意见反馈”功能时读取相册内容
                <br />
                10.添加好友或活动相关二维码扫描时读取相册内容
              </td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
            <tr>
              <td>
                <strong>电话状态（包括设备信息和运行状态）</strong>
              </td>
              <td>
                1.APP安全运行与风控验证
                <br />
                2.内容推荐
                <br />
                3.展示和其他功能优化
              </td>
              <td>
                1.本政策第2.2条描述
                <br />
                2.本政策第2.15条描述
              </td>
              <td>可以，关闭后仅影响对应功能，不影响APP其他功能</td>
            </tr>
          </tbody>
        </table>
        <ol>
          <li>
            <p>
              <strong>我们可能从第三方间接获取您的个人信息</strong>
            </p>
            <p>
              {" "}
              如您使用第三方平台的账号登录时，经过您明确授权账号绑定后，{" "}
              <strong>
                第三方平台会向我们同步您在该平台上使用的头像、昵称、地区（如有）、性别（如有）及好友信息（针对微博平台）
              </strong>{" "}
              ，以使您可以直接登录并使用较真。
            </p>
            <p>
              {" "}
              如您不希望被推荐给微博好友，可以在【我】-【设置】-【隐私】-【不把我推荐给微博好友】中选择关闭。
            </p>
          </li>
          <li>
            <p>
              <strong>征得授权同意的例外</strong>
            </p>
            <p>
              {" "}
              根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：
            </p>
            <p>
              {" "}
              （1）为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
            </p>
            <p> （2）为履行法定职责或者法定义务所必需；</p>
            <p>
              {" "}
              （3）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
            </p>
            <p>
              {" "}
              （4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
            </p>
            <p>
              {" "}
              （5）依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
            </p>
            <p> （6）法律法规规定的其他情形</p>
          </li>
        </ol>
        <h3 id="二、我们如何共享、转让、公开披露您的个人信息">
          二、我们如何共享、转让、公开披露您的个人信息
        </h3>
        <ol>
          <li>
            <p>
              <strong>共享</strong>：
            </p>
            <p>
              {" "}
              对于您的个人信息，我们不会与任何公司、组织和个人进行共享，除非存在以下一种或多种情形：
            </p>
            <p>
              {" "}
              <strong>（1） 事先已得到您的授权；</strong>
            </p>
            <p>
              {" "}
              <strong>（2） 您自行提出的；</strong>
            </p>
            <p>
              {" "}
              <strong>（3） 与商业合作伙伴的必要共享：</strong>
            </p>
            <p>
              {" "}
              <strong>
                您理解并知悉，为了向您提供更完善、优质的产品和服务，我们将授权商业合作伙伴为您提供部分服务。此种情形下，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。请您注意，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们将对信息数据的输出形式、流转、使用进行安全评估与处理，以保护数据安全。同时，我们会对合作伙伴进行严格的监督与管理，一旦发现其存在违规处理个人信息的行为，将立即停止合作并追究其法律责任。
              </strong>
            </p>
            <p> 目前，我们的合作伙伴包括以下类型：</p>
            <p>
              {" "}
              A.
              平台的第三方商家：为帮助您实现产品或服务的购买或者为您提供售后服务，我们会与平台的第三方商家共享
              <strong>您的订单和交易相关信息。</strong>
            </p>
            <p>
              {" "}
              B.
              第三方物流服务商：当您成功下单后，如商品需第三方配送的，我们为了准确、安全向您完成货物的交付及配送。第三方物流公司在发货及配送环节内不可避免地会获知您的
              <strong>相关配送信息</strong>
              。我们向您承诺，我们会以最大努力保障您的个人信息安全，我们会严格要求第三方物流公司对您的个人信息保密，只以配送之目的获悉和使用，不得对外泄露或做其他任何用途。
            </p>
            <p>
              {" "}
              C.广告和统计分析类合作伙伴：为帮助进行广告或决策建议、提高广告有效触达率、进一步了解用户需求，我们可能会与委托我们进行信息推广和广告投放的合作伙伴、或统计分析类合作伙伴共享
              <strong>去标识化的设备信息或统计信息</strong>
              ，这些信息难以或无法与你的真实身份相关联。
              <strong>
                请您知悉，为了您的信息安全，我们目前仅与已签署严格数据安全保密协议的合作伙伴进行合作
              </strong>
              。
            </p>
            <p>
              {" "}
              D.
              内容审核服务商：为保证用户在互联网信息平台上发布的内容是安全的，我们可能会向委托的第三方内容审核服务商共享您在平台上发布的内容信息。
            </p>
            <p>
              {" "}
              E.
              实名认证审核服务商：当您进行实名认证或使用找回账号功能时，我们会向第三方审核服务商共享您的个人信息，以便您顺利找回账号。
            </p>
            <p>
              {" "}
              F.
              测肤功能的服务商：具体测肤功能由与较真合作的第三方服务商提供，当您使用该功能时，我们会向第三方服务商共享您基于此功能向平台提供的个人信息，以便您顺利体验该功能。
            </p>
            <p>
              {" "}
              G.
              第三方小程序：当您使用第三方小程序时，未经您同意，我们不会向这些开发者、运营者共享您的个人信息。当您使用小程序时，小程序可能会使用您授权的相关系统权限，您可以在相应的小程序中撤回该等授权。
            </p>
            <p>
              {" "}
              H.
              数字藏品技术服务提供方：当您使用数字藏品功能，我们会向第三方技术服务商共享您向平台提供的个人信息，以便您顺利体验该功能。
            </p>
            <p>
              {" "}
              I.
              其他业务合作伙伴：委托我们进行推广和广告投放相关的合作伙伴。您授权我们有权与委托我们进行推广和广告投放的合作伙伴共享我们使用您的相关信息集合形成的
              <strong>间接用户画像</strong>
              ，以帮助其进行广告或决策建议、提高广告有效触达率、进一步了解用户需求。{" "}
              <strong>
                但我们承诺：未经您的同意，我们不会与其共享可以识别您身份的个人信息。
              </strong>
            </p>
            <p>
              {" "}
              J.
              合作的第三方SDK服务商：当您使用较真APP中由第三方提供的功能时，我们可能会接入由第三方提供的软件开发包（SDK）以实现相关功能。此时，第三方SDK服务商可能会收集您相关的个人信息。
              <strong>
                我们会尽到审慎义务，对前述第三方SDK进行严格的安全监测，以保护个人信息安全
              </strong>
              ：
            </p>
            <p>
              {" "}
              a.
              一键登录功能：为给您提供账号登录——一键登录功能，第三方服务商可能会获取您的必要设备信息、网络相关信息、地理位置、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息;
            </p>
            <p>
              {" "}
              b.
              第三方账号登录功能：为给您提供第三方账号登录的功能，第三方服务商可能会获取您的必要设备信息、网络相关信息、地理位置、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息；
            </p>
            <p>
              {" "}
              c.
              消息推送服务：为给您提供消息推送，第三方推送服务商可能会获取您的推送SDK版本号、必要设备信息、手机状态信息、地理位置信息、网络相关信息、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息以便推送您可能更感兴趣的信息；
            </p>
            <p>
              {" "}
              d.
              地理位置服务：为给您提供地理位置服务（内容推荐、内容发布、购物时编辑收货信息、资料编辑），第三方地理位置服务商会收集您的设备信息及地理位置信息以便可以向您推送附近内容；
            </p>
            <p>
              {" "}
              e.
              云储存服务：为给您提供云端储存服务（您上传至较真的图片、音视频内容会储存在云端以及有关场景），第三方服务商可能会收集您的设备信息、网络相关信息、运营商信息；
            </p>
            <p>
              {" "}
              f.
              账号安全服务：为保障您和其他用户的账号与财产安全，使您和我们的正当合法权益免受不法侵害，第三方安全服务商可能会收集您的必要设备信息、网络相关信息、运营商信息、日志信息、存储（相册、媒体和其他文件）（包括在后台状态下收集）信息以提供账号安全服务；
            </p>
            <p>
              {" "}
              g.
              支付服务：为保障您顺利购买平台上的商品/服务、虚拟商品，第三方支付服务商可能会收集您的设备信息、网络信息、运营商信息以实现顺利完成支付以及保证支付安全；
            </p>
            <p>
              {" "}
              h.
              直播服务：为保障平台直播功能的顺利使用，第三方服务商可能会收集您的设备信息、网络相关信息、推送SDK版本号、运营商信息。
            </p>
            <p>
              {" "}
              具体请查阅
              <a href="https://www.breal.co/crown/community/privacy-sdk">
                《第三方SDK收集使用信息说明》
              </a>
            </p>
            <p>
              {" "}
              （4）
              当您选择参加我们及我们的关联方或平台上的第三方举办的营销活动时，
              <strong>
                可能需要您提供姓名、性别、通信地址、联系方式、银行账号信息
              </strong>
              。{" "}
              <strong>
                这些信息可能涉及敏感个人信息，拒绝提供可能会影响您参加相关活动，但不会影响其他功能。
              </strong>{" "}
              只有经过您的同意，我们才会将这些信息与关联方或第三方共享，以保障您在活动中获得体验一致的服务，或委托第三方及时向您兑现奖励。
            </p>
            <p>
              {" "}
              （5）
              与我们的关联公司必要共享：我们可能会与我们的关联公司共享您的个人信息。我们只会共享必要的个人信息，且这种共享受本政策所声明的目的的约束。关联公司如要改变个人信息的处理目的，将再次征得您的授权和同意；
            </p>
            <p>
              {" "}
              （6）
              登录、使用较真视频版：您将已持有的较真账号及相关信息授权我们同步到较真视频版后，您将通过同一账号同时使用较真和较真视频版的相关服务和功能。
            </p>
            <p>
              {" "}
              （7）
              您可以基于较真平台与第三人（包括不特定对象）共享您的个人信息或其他信息，但因您的共享行为而导致的信息泄露、被使用及相关情况，与较真无关，较真不因此承担法律责任。
            </p>
          </li>
          <li>
            <p>
              <strong>转让</strong>：
            </p>
            <p>
              {" "}
              转让是指将取得您个人信息的控制权转让给其他公司、组织或个人。除非获取您的明确同意，否则我们不会将您的个人信息转让给任何公司、组织或个人。但下述情形除外：
            </p>
            <p> （1） 事先已征得您的同意；</p>
            <p> （2） 您自行提出的；</p>
            <p>
              {" "}
              （3）
              如果公司发生合并、分立、解散、被宣告破产，将可能涉及到个人信息转让，此种情况下我们会告知您接收方的名称或者姓名和联系方式并要求新的持有您个人信息的公司、组织继续受本政策的约束。若接收方变更个人信息处理目的、处理方式的，我们将要求其重新获取您的明示同意。
            </p>
            <p> （4） 其他法律法规规定的情形。</p>
          </li>
          <li>
            <p>
              <strong>公开披露</strong>：
            </p>
            <p>
              {" "}
              公开披露是指向社会或不特定人群发布信息的行为。原则上，我们不会对您的个人信息进行公开披露。但下述情况除外：
            </p>
            <p> （1） 因公布账号中奖、处罚通知时展示必要的相关脱敏信息；</p>
            <p> （2） 取得您的明示同意后。</p>
          </li>
          <li>
            <p>
              <strong>共享、转让、公开披露个人信息授权同意的例外情形</strong>
            </p>
            <p>
              {" "}
              根据相关法律法规的规定，在以下情形中，我们可能在未事先征得您的授权同意的情况下共享、转让、公开披露您的个人信息：
            </p>
            <p>
              {" "}
              （1）
              为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
            </p>
            <p> （2） 为履行法定职责或者法定义务所必需；</p>
            <p>
              {" "}
              （3）
              为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
            </p>
            <p>
              {" "}
              （4）
              为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
            </p>
            <p>
              {" "}
              （5）
              依照《个人信息保护法》规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
            </p>
            <p> （6） 法律法规规定的其他情形。</p>
            <p>
              {" "}
              请您了解，根据现行法律规定及监管要求，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，无需另行向您通知并征得您的同意。
            </p>
          </li>
        </ol>
        <h3 id="三、我们如何存储和保护您的个人信息">
          三、我们如何存储和保护您的个人信息
        </h3>
        <ol>
          <li>
            <p>
              <strong>存储</strong>：
            </p>
            <p>
              {" "}
              存储地点：我们将从中华人民共和国境内获得的个人信息存放于中华人民共和国境内。以下情形下，我们会确保在履行了法律规定的义务后，向境外实体提供您的个人信息：
            </p>
            <p> （1）适用的法律有明确规定;</p>
            <p> （2）获得您的明确授权;</p>
            <p> （3）您通过互联网进行跨境交易等个人主动行为。</p>
            <p>
              {" "}
              针对以上情形，我们会通过合同等形式确保以不低于本政策规定的程度保护您的个人信息。
            </p>
            <p>
              {" "}
              存储时间：我们承诺始终按照法律的规定在合理必要期限内在存储您个人信息。超出上述期限后，我们将删除您的个人信息或对您的个人信息进行匿名化处理。
            </p>
            <p>
              {" "}
              如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
            </p>
          </li>
          <li>
            <p>
              <strong>保护</strong>：
            </p>
            <p>
              {" "}
              为了保护您的个人信息安全，我们将努力采取各种符合行业标准的安全措施来保护您的个人信息以最大程度降低您的个人信息被毁损、盗用、泄露、非授权访问、使用、披露和更改的风险。我们将积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用，确保未收集与我们提供的服务无关的个人信息。
            </p>
            <p>
              {" "}
              您的账户均有安全保护功能，请妥善保管您的账户及密码信息。较真将通过向其它服务器备份、对用户密码进行加密及相关安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。为防止安全事故的发生，我们已按照法律法规的规定，制定了妥善的预警机制和应急预案。如确发生安全事件，我们将及时将相关情况选择以邮件、信函、电话、推送通知及相关方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。
            </p>
            <p>
              {" "}
              当我们的产品或服务发生停止运营的情形时，我们会及时停止继续收集个人信息的活动。上述变更，我们将以推送通知、公告及相关形式通知你，并在合理的期限内删除你的个人信息或进行匿名化处理（所谓“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。）
            </p>
          </li>
        </ol>
        <h3 id="四、您如何管理您的个人信息">四、您如何管理您的个人信息</h3>
        <p>
          <strong>我们非常重视并尽全力保障您对自己个人信息的相关权利。</strong>
        </p>
        <ol>
          <li>
            <p>自主决定接收信息</p>
            <p>
              {" "}
              在关注中会根据您主动选择关注的账号信息进行展示，您可以根据需要自主选择关注或取消关注。
            </p>
          </li>
          <li>
            <p>个人信息副本的访问、获取、更正和删除相关信息</p>
            <p>
              {" "}
              我们通过交互界面的设计，为您提供了可自行访问、获取、更正和删除相关个人信息的渠道，您可通过以下方式进行设置：
            </p>
            <p>
              {" "}
              （1）
              通过【我-编辑资料】访问、获取、更正自己的个人资料，含头像、昵称、性别、常住地、院校及入学时间、生日、个人介绍；
            </p>
            <p>
              {" "}
              （2）
              通过【我-设置-账号与安全】设置、访问、获取、更正账号信息，含手机号（每月仅可换绑一次）、登录密码、绑定的第三方平台账号（含微信、微博和QQ账号）；
            </p>
            <p>
              {" "}
              （3）
              通过【我-设置-隐私】设置、访问、获取、更正隐私信息，含评论设置、@设置、通讯录推荐设置、微博推荐设置、位置信息设置和黑名单设置；
            </p>
            <p>
              {" "}
              （4） 通过【我-笔记/收藏/赞过】访问、获取、更正、删除相关信息。
            </p>
            <p>
              {" "}
              除上述外，有下列情形之一的，我们将应当主动删除您的个人信息，您也有权请求我们删除：
            </p>
            <p> （1）处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
            <p> （2）我们已停止提供产品或者服务，或者保存期限已届满；</p>
            <p> （3）您已明确撤回同意；</p>
            <p>
              {" "}
              （4）如您有足够的理由认为我们违反法律、行政法规或者违反约定处理您的个人信息；
            </p>
            <p> （5）法律、行政法规规定的其他情形。</p>
          </li>
          <li>
            <p>变更或撤回授权范围</p>
            <p>
              {" "}
              您可通过【我-设置-通知设置/隐私/通用】变更或撤回授权范围，包括：1）微信账号、微博账号和QQ账号的绑定；2）互动、私信、社区内容、和商城通知；3）评论设置、@设置、通讯录推荐设置、微博推荐设置、位置信息设置和黑名单设置；4）系统默认字体的获取和使用设置。
            </p>
            <p>
              {" "}
              您也可以通过设备本身操作系统变更或撤回您对麦克风（录音）、通讯录、通知、网络、相机（摄像头）、日历、悬浮窗、地理位置、相机（摄像头）、存储（相册、媒体和其他文件）、电话状态权限的授权，不同设备型号的功能可能不尽相同，我们将根据您变更或撤回授权的设置进行个人信息的收集，如您撤回授权我们将不再收集与这些权限相关的信息，
              <strong>
                但提请您注意这将导致部分功能或全部功能的不可用。同时，您变更或撤回授权的决定，不会影响我们此前基于您的授权而进行的个人信息处理
              </strong>
              。
            </p>
          </li>
          <li>
            <p>附条件的个人信息转移权</p>
            <p>
              若您需要将您的个人信息转移至其他平台、企业或组织，您可以通过本隐私政策所披露的方式联系我们。我们将对您的请求进行审核，在符合我国网信部门规定的条件下，我们将为您提供转移相应个人信息的途径。
            </p>
          </li>
          <li>
            <p>约束信息系统自动决策</p>
            <p>
              {" "}
              在一些业务功能中，我们可能仅依据信息系统、算法在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。
            </p>
          </li>
          <li>
            <p>
              在符合相关法律要求的情况下，您的近亲属可以对您的相关个人信息行使上述查阅、复制、更正、删除等权利；您另有安排的除外。
            </p>
          </li>
          <li>
            <p>联系我们进行管理</p>
            <p>
              {" "}
              出于安全性和身份识别的考虑，您可能无法直接通过较真交互界面自行访问、更正或删除某些信息（实名认证后的信息、您的部分使用记录及有关信息）；如您确有必要访问、修改或依法要求删除该类信息，请您按照本政策第十条中所提供的联系方式，将您的问题通过邮件发送至指定的联系邮箱，我们将尽快审核所涉问题，并在核验您的用户身份后及时予以回复。
            </p>
          </li>
        </ol>
        <h3 id="五、您如何注销您的账号">五、您如何注销您的账号</h3>
        <p>
          <strong>
            您可以通过【我-设置-帮助与客服-社区问题-如何注销账号】或【我-设置-账号与安全-注销账号】，在满足账号注销的条件下选择【申请注销账号】，来注销您的账号；账号注销后，除法律明确规定必须由我们保留的个人信息外（如您在较真交易相关的信息），您的个人信息将会从较真移除。
          </strong>
        </p>
        <h3 id="六、有关第三方提供产品和或服务的特别说明">
          六、有关第三方提供产品和/或服务的特别说明
        </h3>
        <p>
          较真中可能包括第三方产品和/或服务或链接至第三方提供的信息和/或服务，{" "}
          <strong>
            该第三方产品和/或服务在使用前，您需要跳转到相应的小程序或第三方页面。您使用该第三方服务（包括您向该第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非本政策）约束，您需要仔细阅读其条款并自行决定是否接受。
          </strong>{" "}
          请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。本政策仅适用于我们所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，{" "}
          <strong>第三方使用您信息时的行为由其自行负责。</strong>
        </p>
        <h3 id="七、我们如何使用cookie和其他同类技术">
          七、我们如何使用Cookie和其他同类技术
        </h3>
        <p>
          <strong>1. Cookies的使用</strong>
        </p>
        <p>
          {" "}
          （1）
          在您接受cookies的情况下，较真会在您的计算机以及相关移动设备上设定或取用cookies，以便您能登录或使用依赖于cookies的较真平台服务或功能。较真使用cookies可为您提供更加周到的个性化服务，包括推广服务。
        </p>
        <p>
          {" "}
          （2）
          您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式或在移动设备中设置拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的较真平台服务或功能。
        </p>
        <p> （3） 通过较真所设cookies所取得的有关信息，将适用本政策。</p>
        <h3 id="八、未成年人保护">八、未成年人保护</h3>
        <p>
          <strong>
            以下条款请未成年用户（特别是未满十四周岁的儿童用户）在监护人的陪同下仔细阅读，并由监护人在充分理解后作出是否接受或拒绝本政策的决定：
          </strong>
        </p>
        <ol>
          <li>
            <p>
              <strong>
                若用户是未满18周岁的未成年人（特别是未满十四周岁的儿童用户），应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用较真相关服务。
              </strong>
            </p>
          </li>
          <li>
            <p>
              <strong>
                我们重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用较真相关服务。
              </strong>{" "}
            </p>
          </li>
          <li>
            <p>
              <strong>
                我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据
              </strong>{" "}
              。
              <strong>
                同时我们建立了严格的未成年人信息收集使用规则，以保护儿童和青少年个人信息安全，您可以通过阅读
                <a href="https://www.breal.co/privacy/teenager">
                  《较真儿童/青少年个人信息保护规则》
                </a>
                了解更具体的内容。若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您根据本政策第十条提供的联系方式及时与我们联系。
              </strong>
            </p>
          </li>
        </ol>
        <h3 id="九、我们如何更新隐私政策">九、我们如何更新隐私政策</h3>
        <ol>
          <li>
            为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求更新本政策的条款，这些更新将构成本政策的一部分。未经您明确同意，我们不会削减您依据当前生效的本政策所应享受的权利。
          </li>
        </ol>
        <ol>
          <li>
            如遇本政策更新，我们会通过APP客户端推送通知或其他合理方式通知您，以便您能及时了解本政策的最新版本。
          </li>
        </ol>
        <h3 id="十、如何联系我们">十、如何联系我们</h3>
        <p>
          {" "}
          如您对个人信息保护问题有任何投诉、建议、疑问，或您对本政策有任何疑问，您可以通过以下方式联系我们，我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内予以答复：
          <a href="mailto:support@breal.co">support@breal.co</a>。
        </p>
        <h3 id="十一、其他">十一、其他</h3>
        <ol>
          <li>
            <p>
              <strong>
                因本政策以及我们处理您个人信息事宜引起的任何争议，您可诉至上海市黄浦区人民法院。
              </strong>
            </p>
          </li>
          <li>
            <p>
              <strong>
                如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。
              </strong>
            </p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default PrivacyPolicy
