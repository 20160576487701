import { Col, Row } from "antd"
import React, { useState } from "react"

function FaqHomeOne({ className }: { className?: string }) {
  const [showQues, setQues] = useState(1)
  const openQuestion = (value) => {
    setQues(value)
  }

  const faqs = [
    {
      q: "鉴定师都是什么资质?",
      a: (
        <div>
          中检北京认证鉴定师/中检上海认证鉴定师/日本奢侈品协会认证鉴定师/公检法特邀鉴定师
          鉴定团队综合拥有超15年奢侈品鉴定经验，以为超60万件货品提供鉴定服务。
        </div>
      ),
    },
    {
      q: "鉴定多久可以出结果？",
      a: (
        <div>
          正常情况下鉴定师接单后2小时内给出反馈信息，如遇节假日或公众假期会稍有延长。如果图片拍摄高清到位会出结果，否则需要补图进一步确认。
        </div>
      ),
    },
    {
      q: "商品拍下后多久可以发货?",
      a: (
        <div>
          一般情况下，付款后仓库调出商品再次鉴定后48小时内顺丰包邮发出，贵重物品请务必本人签收。
        </div>
      ),
    },
    {
      q: "回收的商品寄出后多久回款?",
      a: (
        <div>
          鉴定中心在确认收到货品后会第一时间安排鉴定师和估价师查看并给出最终报价，客户确认报价后会在24小时内打款到账。
        </div>
      ),
    },
    {
      q: "有没有客服微信联系方式?",
      a: <div>有的，任何问题可以添加微信：jiaozhen101</div>,
    },
  ]

  return (
    <>
      <section className={`appie-faq-area pb-95 ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">常见问题</h3>
                <p>了解一下常见问题</p>
              </div>
            </div>
          </div>
          <Row gutter={16}>
            {faqs.map((f, i) => {
              return (
                <Col key={i} span={12}>
                  <div
                      className="accrodion-grp animated fadeIn faq-accrodion wow"
                      data-wow-duration="1500ms"
                      data-grp-name="faq-accrodion"
                      style={{marginBottom: 16}}
                    >
                      <div
                        onClick={() => openQuestion(1)}
                        className={`accrodion ${
                          showQues === 1 ? "active" : ""
                        }`}
                      >
                        <div className="accrodion-inner" >
                          <div className="accrodion-title">
                            <h4>{f.q}</h4>
                          </div>
                          <div className="accrodion-content">
                            <div className="inner">
                              <div>{f.a}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </Col>
              )
            })}
          </Row>
        </div>
      </section>
    </>
  )
}

export default FaqHomeOne
