export const isWechatBrowser = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i)) {
    return true
  } else {
    return false
  }
}

export const qiniuImageView = (
  url: string = "",
  width: number = 750,
  height: number = null,
  mode: number = 2
) =>
  !!url
    ? `${url}?imageView2/${mode}/w/${width}${
        height != null ? "/h/" + height : ""
      }`
    : ""
