import React from "react"
import IconOne from "../../assets/images/icon/1.png"
import IconTwo from "../../assets/images/icon/2.png"
import IconThree from "../../assets/images/icon/3.png"
import IconFour from "../../assets/images/icon/4.png"

function ServicesHomeOne({ className }: {className?: string}) {
  return (
    <section
      className={`appie-service-area pt-90 pb-100 ${className}`}
      id="service"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">
                一站式 <br /> 玩转奢侈品
              </h3>
              <p>让你喜爱的各式奢侈品在自由流转</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="icon">
                <i
                  className="fal fa-check-circle"
                  style={{ color: "white", fontSize: 24 }}
                />
                <span>1</span>
              </div>
              <h4 className="appie-title">鉴定服务</h4>
              <p>专业资质团队在线提供鉴定服务，助力购物维权</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="icon">
                <i
                  className="fal fa-recycle"
                  style={{ color: "white", fontSize: 24 }}
                />
                <span>2</span>
              </div>
              <h4 className="appie-title">极速回收</h4>
              <p>极简流程闪电打款，闲置奢品轻松变现</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <i
                  className="fal fa-shopping-bag"
                  style={{ color: "white", fontSize: 24 }}
                />
                <span>3</span>
              </div>
              <h4 className="appie-title">交易购物</h4>
              <p>多重鉴定正品保障，假一赔三买得放心</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="icon">
                <i
                  className="fal fa-play"
                  style={{ color: "white", fontSize: 24 }}
                />
                <span>4</span>
              </div>
              <h4 className="appie-title">二奢直播</h4>
              <p>高颜值主播全方位展示，更直观更带感</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesHomeOne
