import axios from "axios"

const ApiClient = axios.create({
  baseURL: "https://cloud.breal.co",
  timeout: 3000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
})

class API {
  static getDownloadLinks = async () => {
    const res = await ApiClient.get(
      "app_download_links",
    )
    return res.data
  }

  static Authenticate = class {
    static getCertificate = async (params: { id: number }) => {
      const res = await ApiClient.post(
        "webapi/authenticate/certificate",
        params
      )
      return res.data
    }
  }
}

export default API
