import React from "react"

function HeroAbout() {
  return (
    <>
      <div className="appie-about-top-title-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="appie-about-top-title">
                <h2 className="title">
                  致力打造专业、诚信、公平的闲置奢侈品平台
                </h2>
                {/* <p>We offer you a panoply of cutting-edge options.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="appie-about-page-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="appie-about-page-content">
                <h3 className="title">我们是谁</h3>
                <p>
                  广州爱丽社科技有限公司是一家专注于奢侈品二手转售交易的互联网公司。
                </p>

                <p>
                  我们旗下运营”鉴定神器”和“耍大牌闪拍”两款小程序，分别为保真和交易过程提供服务。为了满足服务需求的提升，我们推出了较真App。
                </p>
                <p>
                  我们的愿景是以AI技术+大数据+互联网技术从源头根本杜绝假货，为人们创造一个放心珍品有保证的奢侈品交易消费环境。
                </p>
                {/* <a href="#">
                  View all Members <i className="fal fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeroAbout
