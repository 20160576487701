import React from "react"
import useToggle from "../../Hooks/useToggle"
import HomeOneHeader from "../Home/HomeOneHeader"
import Drawer from "../Mobile/Drawer"
import "./index.less"

const MainFrame = ({ children }) => {
  const [drawer, drawerAction] = useToggle(false)
  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HomeOneHeader action={drawerAction.toggle} />
      <section
        className="mainframe-section"
        style={{ maxWidth: 1180, margin: "0 auto" }}
      >
        {children}
      </section>
    </>
  )
}

export default MainFrame
