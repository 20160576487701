import React, { useState } from "react"
import { Link } from "react-router-dom"
import thumb from "../../assets/images/features-thumb-1.png"
import shapeSix from "../../assets/images/shape/shape-6.png"
import shapeSeven from "../../assets/images/shape/shape-7.png"
import shapeEight from "../../assets/images/shape/shape-8.png"
import lvChipDetectorSS from "../../assets/images/screenshots/lv-chip-detector.jpg"
import lvChipValid from "../../assets/images/screenshots/lv-chip-valid.jpg"
import lvChipInvalid from "../../assets/images/screenshots/lv-chip-invalid.jpg"

function FeaturesHomeOne({ className }) {
  const [tab, setTab] = useState("setting")
  const handleClick = (e, value) => {
    e.preventDefault()
    setTab(value)
  }
  return (
    <section
      className={`appie-features-area pt-100 ${className}`}
      id="features"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="appie-features-tabs-btn">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  onClick={(e) => handleClick(e, "setting")}
                  className={`nav-link ${tab === "setting" ? "active" : ""}`}
                  id="v-pills-home-tab"
                  data-toggle="pill"
                  href="#v-pills-home"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  <i className="fas fa-wifi" /> LV读芯术
                </a>
                <a
                  onClick={(e) => handleClick(e, "real")}
                  className={`nav-link ${tab === "real" ? "active" : ""}`}
                  id="v-pills-profile-tab"
                  data-toggle="pill"
                  href="#v-pills-profile"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  <i className="fas fa-check" /> 读芯特征吻合
                </a>
                <a
                  onClick={(e) => handleClick(e, "fake")}
                  className={`nav-link ${tab === "fake" ? "active" : ""}`}
                  id="v-pills-profile-tab"
                  data-toggle="pill"
                  href="#v-pills-profile"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  <i className="fas fa-exclamation-triangle" /> 假货警告
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className={`${
                  tab === "setting" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center wow animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src={lvChipDetectorSS}
                        alt=""
                        style={{
                          borderTopLeftRadius: 16,
                          borderTopRightRadius: 16,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content wow animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>LV Microchip Detector</span>
                      <h3 className="title">LV读芯术</h3>
                      <p>
                        2021年起LV对旗下产品启用NFC防伪芯片代替旧有的日期编码，使用较真app助你轻松读取芯片位置并判断芯片数据是否符合正品LV的防伪芯片数据特征。
                      </p>
                      <div style={{ fontSize: "1.1em" }}>
                        下载较真APP——首页右上角+号——点击“LV读芯术”——即可开始扫描读取包内芯片信息
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "real" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src={lvChipValid}
                        alt=""
                        style={{
                          borderTopLeftRadius: 16,
                          borderTopRightRadius: 16,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>Traits matched</span>
                      <h3 className="title">
                      读芯特征吻合
                      </h3>
                      <p>
                        系统自动判断芯片各项数据的表面特征是否与正品相符，并展示读取的数据结果。
                      </p>
                      <div>*由于官方版权原因，暂时未能读取加密数据部分，扫描结果仅供参考。</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "fake" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src={lvChipInvalid}
                        alt=""
                        style={{
                          borderTopLeftRadius: 16,
                          borderTopRightRadius: 16,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>Fake Alert</span>
                      <h3 className="title">
                        假货警告
                      </h3>
                      <p>
                        如数据特征与正品不符，或有明显造假特征，APP将出具假货警告。
                      </p>
                      <div>*由于官方版权原因，暂时未能读取加密数据部分，扫描结果仅供参考。</div>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "app" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>Custom Reacyions</span>
                      <h3 className="title">
                        Let the <br /> Conversation flow
                      </h3>
                      <p>
                        Car boot absolutely bladdered posh burke the wireless
                        mush some dodg.
                      </p>
                      <a className="main-btn" href="#">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default FeaturesHomeOne
