import React, { useEffect, useState } from "react"
import API from "../../api/api"
import useToggle from "../../Hooks/useToggle"
import BackToTop from "../BackToTop"
import Drawer from "../Mobile/Drawer"
import FaqHomeOne from "./FaqHomeOne"
import FeaturesHomeOne from "./FeaturesHomeOne"
import FooterHomeOne from "./FooterHomeOne"
import HeroHomeOne from "./HeroHomeOne"
import HomeOneHeader from "./HomeOneHeader"
import PricingHomeOne from "./PricingHomeOne"
import ServicesHomeOne from "./ServicesHomeOne"
import TestimonialHomeOne from "./TestimonialHomeOne"

function HomeOne() {
  const [drawer, drawerAction] = useToggle(false)
  const toggle = drawerAction.toggle

  const [downloadLinks, setDownloadLinks] = useState()

  useEffect(() => {
    fetchAppDownloadLinks()
  }, [])

  const fetchAppDownloadLinks = async () => {
    try {
      const res = await API.getDownloadLinks()
      setDownloadLinks(res)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Drawer drawer={drawer} action={toggle} />
      <HomeOneHeader action={toggle} />
      <HeroHomeOne downloadLinks={downloadLinks} />
      <ServicesHomeOne />
      <FeaturesHomeOne className={undefined} />
      <TestimonialHomeOne />
      <PricingHomeOne />
      <FaqHomeOne />
      <FooterHomeOne downloadLinks={downloadLinks} />
      <BackToTop />
    </>
  )
}

export default HomeOne
