import React, { useEffect } from "react"
import "./tos_privacy.less"

const Tos = () => {
  useEffect(() => {
    document.title = "较真 - 用户隐私政策"
  }, [])
  return (
    <div className="html-content">
      <p>
        <strong>
          感谢您选择注册并使用较真，在使用“较真”软件及相关服务前，请您认真阅读本协议（特别是以加粗方式提示您注意的可能存在免除或者限制责任等重要内容的条款），并确认承诺同意遵守本协议之全部约定。本协议由您与广州爱丽社科技有限公司（包括其关联机构，以下合称“本公司”）于您点击同意本协议之时在上海市黄浦区签署并生效。
        </strong>
      </p>
      <h3 id="一、协议条款的确认及接受">一、协议条款的确认及接受</h3>
      <p>
        <strong>
          1.1 较真（包括网址为 <a href="https://www.breal.co/">www.breal.co</a>{" "}
          的网站，以及可在IOS 系统及Android
          系统中运行的名为“较真”、较真视频版（暂定，具体名称以上线时标注的为准）及其他不同版本的应用程序，以下简称"本网站"或“较真”）由广州爱丽社科技有限公司（包括其关联机构，以下合称“本公司”）运营并享有完全的所有权及知识产权等权益，较真提供的服务将完全按照其发布的条款和操作规则严格执行。
        </strong>
      </p>
      <p>
        <strong>
          1.2
          您确认同意本协议（协议文本包括《较真用户服务协议》、《较真用户隐私政策》、《较真社区规范》及较真已公示或将来公示的各项规则及提示，所有前述协议、规则及提示乃不可分割的整体，具有同等法律效力，共同构成用户使用较真及相关服务的整体协议，以下合称“本协议”）所有条款并完成注册程序时，本协议在您与本公司间成立并发生法律效力，同时您成为较真正式用户。
        </strong>
      </p>
      <h3 id="二、帐号注册及使用规则">二、帐号注册及使用规则</h3>
      <p>
        2.1
        您注册成功后，较真将给予您一个用户帐号及相应的密码，该用户帐号和密码由您负责保管；
        <strong>您应当对以其用户帐号进行的所有活动和事件负法律责任。</strong>
      </p>
      <p>
        2.2{" "}
        <strong>
          您须对在较真的注册信息的真实性、合法性、有效性承担全部责任，您不得冒充他人（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设帐号；不得利用他人的名义发布任何信息；不得恶意使用注册帐户导致其他用户误认；否则较真有权立即停止提供服务，收回其帐号并由您独自承担由此而产生的一切法律责任。
        </strong>
      </p>
      <p>
        2.3{" "}
        <strong>
          您理解且确认，您在较真注册帐号的所有权及有关权益均归本公司所有，您完成注册手续后仅享有该帐号的使用权。您的帐号仅限于您本人使用，未经本公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。如果本公司发现或者有合理理由认为使用者并非帐号初始注册人，公司有权在未通知您的情况下，暂停或终止向该注册帐号提供服务，并有权注销该帐号，而无需向注册该帐号的用户承担法律责任。
        </strong>
      </p>
      <p>
        2.4{" "}
        <strong>
          您理解并确认，除登录、使用较真及相关服务外，您还可以用较真帐号登录使用本公司及其关联方或其他合作方提供的其他软件、服务；同时，若您在相应软件、服务中确认相关内容同步功能的，则您在前述软件中发布的内容及相关数据将同步至您的较真帐号中。您以较真帐号登录并使用前述服务的，同样应受其他软件、服务实际提供方的用户协议及其他协议条款约束。
        </strong>
      </p>
      <p>
        2.5{" "}
        <strong>
          为提高用户内容曝光量及发布效率，您同意将您在较真帐号中所发布的全部内容均授权本公司以同一用户帐号自动同步发布至较真视频版（暂定，具体名称以上线时标注的为准）及较真其他不同版本、本公司及/或关联方运营的其他软件及网站，您的帐号之头像、昵称等公开信息可能会一并同步。用户在较真发布、修改、删除内容的操作，均会同步到上述软件及网站中。
        </strong>
      </p>
      <p>
        2.6{" "}
        <strong>
          为了充分使用帐号资源，如您在注册后未及时进行初次登录使用或连续超过二个月未登录帐号并使用等情形，本公司有权收回您的注册帐号。
        </strong>
      </p>
      <p>
        2.7
        您承诺不得以任何方式利用较真直接或间接从事违反中国法律、以及社会公德的行为，较真有权对违反上述承诺的内容予以删除。
      </p>
      <p>
        2.8{" "}
        <strong>
          您不得利用本网站制作、上载、复制、发布、传播或者转载如下内容：
        </strong>
        （1）反对宪法所确定的基本原则的；（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；（3）损害国家荣誉和利益的；（4）煽动民族仇恨、民族歧视，破坏民族团结的；（5）破坏国家宗教政策，宣扬邪教和封建迷信的；（6）散布谣言，扰乱社会秩序，破坏社会稳定的；（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；（8）侮辱或者诽谤他人，侵害他人合法权益的；（9）侵害未成年人合法权益或者损害未成年人身心健康的；（10）含有法律、行政法规禁止的其他内容的信息。
      </p>
      <p>
        2.9{" "}
        <strong>
          较真鼓励用户充分利用较真平台自由地发布和共享自己的信息，用户对于其创作并在较真上发布的合法内容（包括但不限于文字、图片、视频、音频等）依法享有著作权。您不应通过较真发布他人受知识产权法律等保护的内容，除非获得他人的合法授权。就您发布的内容，若第三方向较真投诉并提交初步证据，经较真判断投诉属实的，较真将会删除这些内容。如因您发布的内容给较真带来损失的，您应当负责赔偿。
        </strong>
      </p>
      <p>
        2.10
        经本公司许可后，您、第三方对较真的信息内容的分享、转发等行为，还应符合以下规范：
        （1）对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经公司事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；
        （2）不得对较真的源网页进行任何形式的任何改动，包括但不限于较真的首页链接、广告系统链接等入口，也不得对较真的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；
        （3）应当采取安全、有效、严密的措施，防止较真的信息内容被第三方通过包括但不限于“蜘蛛”（spider）程序等任何形式进行非法获取；
        （4）不得把相关数据内容用于公司书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用。
        （5）您向任何第三人分享、转发、复制较真信息内容的行为，还应遵守公司为此制定的其他规范和标准，如《较真社区规范》及其他已公示或将来公示的各项规则及提示等。
      </p>
      <p>
        2.11
        较真有权对您使用较真的情况进行审查和监督，如您在使用较真时违反任何上述规定，较真或其授权的人有权要求您改正或直接采取一切必要的措施（包括但不限于更改或删除您张贴的内容、暂停或终止您使用较真的权利）以减轻您的不当行为造成的影响。
      </p>
      <h3 id="三、服务内容">三、服务内容</h3>
      <p>
        3.1
        本公司可能为不同的终端设备及使用需求开发不同的应用程序软件版本，您应当根据实际设备及需求状况获取、下载、安装合适的版本。
      </p>
      <p>
        <strong>
          3.2
          本网站的服务具体内容根据实际情况提供，较真保留变更、中断或终止部分或全部服务的权利。较真不承担因业务调整给您造成的损失。除非本协议另有其它明示规定，增加或强化目前本网站的任何新功能，包括所推出的新产品，均受到本协议之规范。您了解并同意，本网站服务仅依其当前所呈现的状况提供，对于任何用户通讯或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，较真均不承担任何责任。如您不接受服务调整，请停止使用本服务；否则，您的继续使用行为将被视为其对调整服务的完全同意且承诺遵守。
        </strong>
      </p>
      <p>
        3.3
        较真在提供服务时，可能会对部分服务的用户收取一定的费用。在此情况下，较真会在相关页面上做明确的提示。如您拒绝支付该等费用，则不能使用相关的服务。
      </p>
      <p>
        3.4
        您理解，较真仅提供相关的服务，除此之外与相关服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担。
      </p>
      <p>
        3.5{" "}
        <strong>
          较真提供的服务内容可能包括：文字、软件、声音、图片、视频、图表等。所有这些内容受著作权、商标和其它财产所有权法律的保护。您只有在获得较真或其他相关权利人的授权之后才能使用这些内容，不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
        </strong>
      </p>
      <p>
        3.6 <strong>较真会员服务</strong>
      </p>
      <p>
        3.6.1
        较真会员，是指在较真具有良好消费记录的个人注册用户根据较真公布的收费标准支付相应的费用后获取的特殊资格。以下称“会员”。
      </p>
      <p>
        3.6.2
        会员服务，指会员享有的特殊服务，包括以相对优惠的价格购买指定商品（即会员专享价），以及其他由较真明示的优惠措施。您开通会员服务时，须按照较真公布的收费标准支付相应的会员服务费用。基于业务的发展，较真有权调整收费标准，调整后的收费标准自公布之日起生效。
      </p>
      <p>
        3.6.3
        会员知悉并确认，当开通会员服务后，若您中途取消服务或终止资格的，较真将不退还您已支付的会员服务费用。
      </p>
      <p>
        3.6.4
        使用会员服务过程中，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应当承担因此而导致的一切后果，较真有权向您的监护人追偿。
      </p>
      <p>
        3.6.5
        您知悉并同意，较真有权通邮件、短信等方式，向您发送会员相关活动的信息；
      </p>
      <p>
        3.6.6
        您确认会员服务仅限您本人使用，同时，您保证，您将合理使用会员服务，不会利用会员服务非法获利，不以任何形式转让您所享有的会员服务，不以任何形式将您所享有的会员服务借给他人使用，如较真有合理理由怀疑您存在不当使用会员服务时，较真有权取消您的会员资格且不退还您支付的会员服务费用，因此产生的相关责任及损失均由您自行承担，给较真造成损失的，较真保留向您追偿的权利。
      </p>
      <p>
        3.6.7
        较真保留在法律法规允许的范围内自行决定是否接受您的会员服务申请、调整会员服务内容、取消会员资格等权利。
      </p>
      <p>
        3.7 <strong>商品/服务的购买与评价</strong>
      </p>
      <p>
        3.7.1{" "}
        <strong>
          您可以通过较真商城购买较真或者第三方商家为您提供的商品/服务，商品/服务来源以是否标注“自营”予以区分。
        </strong>
      </p>
      <p>
        3.7.2{" "}
        <strong>
          您在较真下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。
        </strong>
      </p>
      <p>
        3.7.3{" "}
        <strong>
          您理解并同意：较真上展示的商品/服务和价格等信息为向您发出的要约。您下单时须填写您希望购买的商品/服务数量、价款及支付方式、收货人、联系方式、收货地址等内容；付款及订单提交成功前，您有权修改所填信息。付款及订单提交成功后，将视您与销售商之间就您购买的商品/服务成立了合同关系，销售商会按照您在订单中订购的商品/服务进行发货。
        </strong>
      </p>
      <p>
        3.7.4{" "}
        <strong>
          尽管销售商做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，本软件无法避免您提交的订单信息中的商品出现缺货情况；如您下单所购买的商品发生缺货，您有权取消订单以解除电子合同，销售商在取得您同意的前提下可以取消订单以解除电子合同，若您已经付款，则为您办理退款。
        </strong>
      </p>
      <p>
        3.7.5{" "}
        <strong>
          您有权在较真提供的
          <a href="https://school.breal.co/rule/detail/5d6934fd0000000000000000/5d9ee0de0774b000158ee55d">
            评价系统
          </a>
          中对与其达成交易的商品/服务进行评价。您的评价内容应当客观真实，不应包含任何污言秽语、色情低俗、恶意贬低及法律法规与本协议列明的其他禁止性信息；您不应以不正当方式帮助商家提升销量、信用或利用评价权利对相关商家进行恐吓、敲诈勒索等违法行为。较真可按照本协议及相关法律法规的规定对您实施上述行为所产生的评价信息进行删除或屏蔽。
        </strong>
      </p>
      <h3 id="四、知识产权">四、知识产权</h3>
      <p>
        <strong>
          4.1
          本公司在“较真”软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、商标、标识、音频、视频、图表、版面设计、电子文档等）的知识产权属于本公司所有。同时本公司提供服务所依托的软件的著作权、专利权、商标及其他知识产权均归本公司所有。未经本公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载“较真”软件及相关服务中的内容）。
        </strong>
      </p>
      <p>
        <strong>
          4.2
          请您在任何情况下都不要私自使用公司的包括但不限于“行吟”、“较真”和“breal.co”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经公司事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由您承担全部法律责任。
        </strong>
      </p>
      <h3 id="五、用户授权及隐私保护">五、用户授权及隐私保护</h3>
      <p>
        5.1
        除非有相反证明，您知悉、理解并同意，为使您的作品得到更好的分享及推广，提高其传播价值及影响力，您通过较真上传、发布或传输的内容（包括但不限文字，图像，音频，视频、直播内容等各种形式的内容及其中包括的音乐、声音、台词、视觉设计、对话等所有组成部分），授予本公司及其关联方、控制公司、继承公司一项全球范围内、免费、非独家、可再许可（通过多层次）的权利（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权及制作衍生品、表演和展示的权利等），上述权利的使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等使用。您在此确认并同意，“较真”软件及相关服务、公司和/或公司品牌有关的任何宣传、推广、广告、营销和/或研究中使用和以其他方式开发内容（全部或部分）的权利和许可。为避免疑义，您理解并同意，上述授予的权利包括使用、复制和展示您拥有或被许可使用并植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记（如有）以及任何其他品牌、营销或推广资产、物料、素材等的权利和许可。基于部分功能的特性，您通过较真发布的内容（包括但不限于内容中包含的声音、音频或对话等）可供其他用户使用“较真”软件创作及发布相关内容时使用。
      </p>
      <p>
        5.2{" "}
        <strong>
          您确认并同意授权本公司以公司名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，本公司有权对维权事宜做出决策并独立实施。
        </strong>
      </p>
      <p>
        5.3
        保护用户隐私是较真的一项基本政策，较真保证不对外公开或向第三方提供您的注册资料及您在使用本网站服务时存储在较真的非公开内容，但下列情况除外：（1）事先获得您的明确授权；（2）根据有关的法律法规要求；（3）按照相关政府主管部门的要求；（4）为维护社会公众的利益；（5）维护较真的合法权益。
      </p>
      <p>
        5.4
        在获得您授权的情况下，较真可能会与第三方合作向您提供相关的服务，在此情况下，如该第三方同意承担与较真同等的保护用户隐私的责任，则较真可将您的注册资料等提供给该第三方。
      </p>
      <p>
        5.5
        用户关注信息公开：较真提供的某些服务项目中有“关注”功能，用户选择“关注”后，可以在“我的关注”中查看自己关注的信息，包括关注的“用户”、“商家”、“标签”、“专辑”等，您须知，较真平台对用户关注的项目是公开的，即其他用户可以查看到该用户关注的项目。另外您可以在“设置”-“隐私设置”中调整“评论设置”、“通讯录设置”“微博设置”等，自行调整评论、以及添加好友的方式等。
      </p>
      <p>
        5.6 您可以向较真申请注销账户。
        <strong>
          注销账户后，较真将停止为您提供产品/服务，并依据您的要求，除法律法规另有规定外，较真将删除其在较真平台上的个人信息。
        </strong>
      </p>
      <h3 id="六、免责声明">六、免责声明</h3>
      <p>
        6.1
        您将照片、个人信息等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。
        <strong>
          您明确同意其使用“较真”软件及相关服务所存在的风险（包括但不限于受到第三方侵权或对第三方造成侵权）完全由您自己承担；因您使用“较真”软件及相关服务而产生的一切后果也由您自己承担，较真对此不承担任何责任。
        </strong>
      </p>
      <p>
        6.2{" "}
        <strong>
          较真不担保本网站服务一定能满足您的要求，也不担保本网站服务不会中断，对本网站服务的及时性、安全性、准确性、真实性、完整性也都不作担保。
        </strong>
      </p>
      <p>
        6.3{" "}
        <strong>
          对于因不可抗力或较真不能控制的原因造成的本网站服务中断或其它缺陷，较真不承担任何责任，但较真将尽力减少因此而给您造成的损失和影响。
        </strong>
      </p>
      <p>
        6.4{" "}
        <strong>
          根据有关法律法规，较真在此郑重提请您注意，较真作为信息存储空间及网络交易平台的提供者，任何经由本网站以上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料（以下简称“内容”），无论系公开还是私下传送，均由内容提供者承担责任。较真无法控制经由本网站传送之内容，因此不保证内容的正确性、完整性或品质。在任何情况下，较真均不为任何内容负责，包含但不限于任何内容之任何错误或遗漏，以及经由本网站服务以张贴、发送电子邮件或其它方式传送任何内容而衍生之任何损失或损害。但较真有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本网站服务的全部或部分，保存有关记录，并向有关机关报告。
        </strong>
      </p>
      <h3 id="七、违约责任">七、违约责任</h3>
      <p>
        7.1
        针对您违反本协议或其他服务条款的行为，本公司有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除跟帖、短期禁止发言、限制帐号部分或者全部功能直至永久关闭帐号等措施。本公司有权公告处理结果，且有权根据实际情况决定是否恢复使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。
      </p>
      <p>
        7.2
        因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致本公司及其关联公司、控制公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿本公司及其关联公司、控制公司因此遭受的全部损失。
      </p>
      <h3 id="八、未成年人使用条款">八、未成年人使用条款</h3>
      <p>
        8.1{" "}
        <strong>
          若您是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用“较真”软件及相关服务。
        </strong>
      </p>
      <p>
        8.2
        较真重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，
        <strong>
          并应当在取得监护人的同意以及在监护人指导下正确使用“较真”软件及相关服务。
        </strong>
      </p>
      <p>
        8.3{" "}
        <strong>
          未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。
        </strong>
      </p>
      <p>8.4 未成年人用户特别提示</p>
      <p>
        <strong>
          未成年人使用“较真”软件及相关服务应在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，影响日常的学习生活，养成良好上网习惯。
        </strong>
        未成年人用户及监护人在使用“较真”软件及相关服务使应必须遵守《全国青少年网络文明公约》：
        <strong>要善于网上学习,不浏览不良信息。</strong>
        <strong>要诚实友好交流,不侮辱欺诈他人。</strong>
        <strong>要增强自护意识,不随意约会网友。</strong>
        <strong>要维护网络安全,不破坏网络秩序。</strong>
        <strong>要有益身心健康,不沉溺虚拟时空。</strong>
      </p>
      <p>
        8.5{" "}
        <strong>
          为更好地保护未成年人隐私权益，较真特别提醒您慎重发布包含未成年人素材的内容，一经发布，即视为您已获得权利人同意在“较真”软件及相关服务展示未成年人的肖像、声音等信息，且允许较真依据本协议使用、处理该等与未成年人相关的内容。如权利人通知较真您发布的内容侵犯未成年人权利、出于保护权利人及未成年人权利的考虑，较真有权对您发布的内容进行处理并通知您。
        </strong>
      </p>
      <p>
        8.6
        对于未成年人的隐私保护，较真将严格按照平台公布的《较真用户隐私政策》中阐述的方式、方法执行。
      </p>
      <p>
        8.7 <strong>监护人特别提示</strong>
      </p>
      <p>
        8.7.1 法定监护人应指导子女上网应该注意的安全问题，防患于未然。
        <strong>
          如您的被监护人使用“较真”软件及相关服务的，您作为监护人应指导并监督被监护人的注册和使用行为，如您的被监护人申请注册较真帐号，较真将有权认为其已取得您的同意。
        </strong>
      </p>
      <p>
        8.7.2{" "}
        <strong>
          您的被监护人在使用“较真”软件及相关服务时可能使用充值、打赏、较真卡会员服务、商品/服务购买等功能。
        </strong>
        较真不鼓励未成年人使用“较真”软件及相关服务进行任何消费行为。
        <strong>
          您作为监护人，请保管好您的支付设备、支付账户及支付密码等，以避免被监护人在未取得您同意的情况下通过您的较真帐号使用充值、打赏、较真卡会员服务、商品/服务购买等功能。
        </strong>
      </p>
      <h3 id="九、协议修改">九、协议修改</h3>
      <p>
        9.1{" "}
        <strong>
          较真有权根据法律法规政策、国家有权机构或公司经营要求修改本协议的有关条款，较真将会通过适当方式在网站上予以公示。
        </strong>
      </p>
      <p>
        9.2
        若您不同意较真对本协议相关条款所做的修改，您有权停止使用本网站服务。如果您继续使用本网站服务，则视为您接受较真对本协议相关条款所做的修改。
      </p>
    </div>
  )
}

export default Tos
