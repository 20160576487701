import React, { useState } from "react"

function PricingHomeOne({ className }: {className?: string}) {
  const [toggleSwitch, setSwitchValue] = useState(true)
  return (
    <>
      <section className={`appie-pricing-area pt-90 pb-90 ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">鉴定服务</h3>
                <p>中检认证资质鉴定师团队</p>
                <p>超15年鉴定经验</p>
              </div>
            </div>
          </div>
          <div className="tabed-content">
            {toggleSwitch ? (
              <div id="month">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 wow animated fadeInLeft">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>标准鉴定</h6>
                        <div className="price-range">
                          <sup>¥</sup> <span>50</span>
                        </div>
                        <p>适合多数一般鉴定场景</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <i className="fal fa-check" /> 电子鉴定证书
                          </li>
                          <li>
                            <i className="fal fa-check" /> 快速获得鉴定结果
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6>精细鉴定</h6>
                        <div className="price-range">
                          <sup>¥</sup> <span>99</span>
                        </div>
                        <p>适合价格较高的鉴定标的</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <i className="fal fa-check" /> 电子鉴定证书
                          </li>
                          <li>
                            <i className="fal fa-check" /> 细节部位鉴定勾选
                          </li>
                          <li>
                            <i className="fal fa-check" /> 双鉴定师二次复检
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-rebon">
                        <span>最多人选择</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>专家鉴定</h6>
                        <div className="price-range">
                          <sup>¥</sup> <span>199</span>
                        </div>
                        <p>专家级鉴定师亲自鉴定</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <i className="fal fa-check" /> 电子鉴定证书
                          </li>
                          <li>
                            <i className="fal fa-check" /> 细节部位鉴定勾选
                          </li>
                          <li>
                            <i className="fal fa-check" /> 双鉴定师二次复检
                          </li>
                          <li>
                            <i className="fal fa-check" /> 专家级鉴定师亲自鉴定
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div id="year">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 wow animated fadeInLeft">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Fresh</h6>
                        <div className="price-range">
                          <sup>$</sup> <span>32</span>
                          <p>/Yearly</p>
                        </div>
                        <p>Get your 14 day free trial</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <i className="fal fa-check" /> 60-day chat history
                          </li>
                          <li>
                            <i className="fal fa-check" /> 15 GB cloud storage
                          </li>
                        </ul>
                        <div className="pricing-btn mt-35">
                          <a className="main-btn" href="#">
                            Choose plan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6>Sweet</h6>
                        <div className="price-range">
                          <sup>$</sup> <span>116</span>
                          <p>/Yearly</p>
                        </div>
                        <p>Billed $276 per website annually.</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <i className="fal fa-check" /> 60-day chat history
                          </li>
                          <li>
                            <i className="fal fa-check" /> 50 GB cloud storage
                          </li>
                          <li>
                            <i className="fal fa-check" /> 24/7 Support
                          </li>
                        </ul>
                        <div className="pricing-btn mt-35">
                          <a className="main-btn" href="#">
                            Choose plan
                          </a>
                        </div>
                      </div>
                      <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single">
                      <div className="pricig-heading">
                        <h6>Juicy</h6>
                        <div className="price-range">
                          <sup>$</sup> <span>227</span>
                          <p>/Yearly</p>
                        </div>
                        <p>Billed $276 per website annually.</p>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <i className="fal fa-check" /> 60-day chat history
                          </li>
                          <li>
                            <i className="fal fa-check" /> Data security
                          </li>
                          <li>
                            <i className="fal fa-check" /> 100 GB cloud storage
                          </li>
                          <li>
                            <i className="fal fa-check" /> 24/7 Support
                          </li>
                        </ul>
                        <div className="pricing-btn mt-35">
                          <a className="main-btn" href="#">
                            Choose plan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default PricingHomeOne
